import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuPlacement } from 'react-select';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { Add, Gear } from '../../svg';
import { GenericMultiSelect } from '../GenericMultiSelect';
import { useInitialFillCategories } from '../hooks/useInitialFillTheFilterChoices';
import { useNotificationBanner } from '../hooks/useNotification';
import { CategoryModalNew } from '../CategoryModalNew';
import { CategoryModalManage } from '../CategoryModalManage';
import { utilsSelector } from '../../redux/utils/selectors';
import { setTranslationsIds } from '../../redux/utils/actions';

interface CategorySelectorIndexProps {
  newsId: string;
  currentValue?: string;
  menuPlacement?: MenuPlacement;
  onChange: (newValue: string | undefined) => void;
}

export const CategorySelectorIndex: FC<CategorySelectorIndexProps> = ({
  newsId,
  currentValue,
  menuPlacement,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { refetch } = useInitialFillCategories();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);

  const { categories } = useSelector(filterChoicesSelector);
  const { translationsIds } = useSelector(utilsSelector);

  const categoriesMapped = useMemo<any[]>(
    () =>
      categories.map((category) => {
        return {
          value: category.name,
          label: category.name,
        };
      }),

    [categories]
  );

  const defaultValueIds = useMemo<any[]>(
    () => (currentValue ? [currentValue] : []),

    [currentValue]
  );

  const { dispatchNotificationBanner } = useNotificationBanner();

  return (
    <>
      <GenericMultiSelect
        id="CategorySelectorIndex"
        menuPlacement={menuPlacement}
        showSelectedOption={true}
        allowOnlyOneValue={true}
        onMenuOpen={() => refetch()}
        onChange={(newValue) => {
          let disableCache = false;
          if (newValue.length === 1) {
            disableCache = true;
            onChange(newValue[0].value);
          } else if (newValue.length > 1) {
            dispatchNotificationBanner({
              title: 'Operazione non permessa',
              text: 'Rimuovere la categoria precedente prima di assegnarne una nuova',
              ok: false,
            });
            return;
          } else {
            disableCache = true;
            onChange(undefined);
          }
          if (disableCache) {
            // sfrutto translationsIds per forzare il refetch della news
            dispatch(setTranslationsIds(translationsIds.concat(newsId)));
          }
        }}
        /*inputs={[
        {
          label: 'Nuova categoria',
          Icon: <Add className="h-6 w-6" />,
          value: '1',
          onClick: (...args: any[]) => alert(args[0]),
        },
      ]}*/
        actions={[
          {
            label: 'Gestisci categorie',
            Icon: <Gear className="h-6 w-6 fill-black" />,
            value: '0',
            onClick: () => setShowManageModal(true),
          },
          {
            label: 'Nuova categoria',
            Icon: <Add className="h-6 w-6 fill-black" />,
            value: '1',
            onClick: () => setShowAddModal(true),
          },
        ]}
        options={categoriesMapped}
        placeholder={'Assegna categoria'}
        defaultValueIds={defaultValueIds}
        menuRight={true}
      />
      {showAddModal && <CategoryModalNew setShowAddModal={setShowAddModal} />}
      {showManageModal && (
        <CategoryModalManage setShowManageModal={setShowManageModal} />
      )}
    </>
  );
};
