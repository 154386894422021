import { FC, useMemo } from 'react';
import { DateFilter } from '../DateFilter';
import { KeywordsFilter } from '../KeywordsFilter';
import { ProvidersFilter } from '../ProvidersFilter';
import { ContentTypesFilter } from '../ContentTypeFilter';
import { BookmarkColorSelector } from '../BookmarkColorSelector';
import { Pin } from '../Pin';
import { Button } from '../Button';
import { Text } from '../Text';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkingAreaFilter } from '../../redux/workingAreas/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { NewsSearchWithoutSuggestions } from '../NewsSearchWithoutSuggestions';
import { Divider } from '../../svg';
import { SvgIcon } from '../SvgIcon';
import { WindowType } from '@nwa/graphql';
import { setShowNewsSearchModal } from '../../redux/utils/actions';
import { SentimentFilter } from '../SentimentFilter';

export interface FilterBoxChildrenProps {
  isDisabled: boolean;
}

export const FilterBox: FC = () => {
  const dispatch = useDispatch();
  const filterSelector = useSelector(newsFilterSelector);
  const { draftWorkingArea, selectedWindow } =
    useSelector(workingAreasSelector);

  const disableFilter = useMemo(
    () =>
      selectedWindow
        ? filterSelector.fullSearchTextError ||
          draftWorkingArea?.windows.filter((w) => w.id === selectedWindow)[0]
            ?.value.type !== WindowType.SEARCH
        : true,
    [
      selectedWindow,
      filterSelector.fullSearchTextError,
      draftWorkingArea?.windows,
    ]
  );
  return (
    <div className="h-12 w-full pl-3 py-2 mt-1 flex items-center justify-between">
      <div className="flex items-center">
        <div className="flex mr-1">
          <NewsSearchWithoutSuggestions
            isDisabled={!filterSelector.fullSearchTextError && disableFilter}
          />
        </div>
        <div className="flex mr-1">
          <KeywordsFilter isDisabled={disableFilter} />
        </div>
        <div className="flex mr-1">
          <DateFilter isDisabled={disableFilter} />
        </div>
        <div className="flex mr-1">
          <ProvidersFilter isDisabled={disableFilter} />
        </div>
        <div className="flex mr-1">
          <ContentTypesFilter isDisabled={disableFilter} />
        </div>
        <div className="flex mr-1">
          <SentimentFilter isDisabled={disableFilter} />
        </div>
        <div className="flex mr-2">
          <Button
            className="flex ml-1 h-10"
            color="primary"
            isDisabled={!filterSelector.fullSearchTextError && disableFilter}
            onClick={() => {
              if (filterSelector.fullSearchTextError) {
                dispatch(setShowNewsSearchModal(true));
              } else {
                dispatch(setWorkingAreaFilter(filterSelector));
              }
            }}
          >
            <Text text="Cerca" />
          </Button>
        </div>
      </div>
      <div className="flex items-center ">
        <div className="m-1">
          <Pin />
        </div>
        <div className="m-1">
          <SvgIcon svg={<Divider />} />
        </div>
        <div className="m-1">
          <BookmarkColorSelector />
        </div>
      </div>
    </div>
  );
};
