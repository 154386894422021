import { FC, useRef, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeDateRange,
  setWorkingAreaFilter,
} from '../../redux/workingAreas/actions';
import { NewsSummaryElement } from '../NewsSummaryElement';
import { SvgIcon } from '../SvgIcon';
import { NewWindowPlaceholder } from '../../svg';
import { Text } from '../Text';
import { useGetNews } from '../hooks/useGetNews';
import { BackToLive } from '../WorkingArea/BackToLive';
import { setDateRangeFilter } from '../../redux/newsFilter/actions';
import { LoadingTab } from '../WorkingArea/LoadingTab';
import InfiniteScroll from 'react-infinite-scroll-component';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';

interface TabProps {
  id: string;
}

export const TabMobile: FC<TabProps> = ({ id }) => {
  const dispatch = useDispatch();

  const { dateRange, draftWorkingArea } = useSelector(workingAreasSelector);

  const [showBackToTop, setShowBackToTop] = useState(false);
  //const [updates, setUpdates] = useState(0);
  const limit = 50;

  const { data, loading, fetchMore, hasMore } = useGetNews(
    id,
    limit,
    !showBackToTop
  );

  const listenScrollEvent = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    setShowBackToTop(event.currentTarget.scrollTop > 0);
  };

  /*useEffect(() => {
    // mi serve per cambiare la key in modo da forzare un rerender
    // dopo aver inserito una news in rassegna/hl
    setUpdates((currentUpdates) => currentUpdates + 1);
  }, [data]);*/

  const ref = useRef<HTMLDivElement | null>(null);

  const filterSelector = useSelector(newsFilterSelector);

  const backToLive = (e: React.MouseEvent<HTMLElement>) => {
    if (filterSelector.dateRange) {
      dispatch(setDateRangeFilter(undefined));
      dispatch(removeDateRange());
      dispatch(
        setWorkingAreaFilter({ ...filterSelector, dateRange: undefined })
      );
    }
    ref.current?.scrollTo({ top: 0 });
    e.stopPropagation();
  };

  const isNlp = React.useMemo(
    () =>
      !!draftWorkingArea?.windows.filter((w) => w.id === id)[0]?.value?.search
        ?.fullSearchText && !dateRange.filter((dr) => dr.id === id)[0]?.date,
    [dateRange, draftWorkingArea?.windows, id]
  );

  return (
    <Container
      className="scrollbar overflow-y-auto"
      onScroll={(event) => listenScrollEvent(event)}
      ref={ref}
      id={'container' + id}
    >
      {data.length > 0 ? (
        <InfiniteScroll
          dataLength={data.length} //This is important field to render the next data
          next={fetchMore}
          hasMore={hasMore}
          loader={
            <p className="text-center">
              <Text text="Caricamento" className="font-bold" />
              ...
            </p>
          }
          endMessage={
            <p className="text-center">
              <Text text="Fine risultati" className="font-bold" />
            </p>
          }
          scrollableTarget={'container' + id}
        >
          <>
            {data.map((news) => (
              <NewsSummaryElement
                key={news.id + id /*+ updates*/}
                newsItem={news}
                selectedWindow={true}
                tabId={id}
                html={false}
                isNlp={isNlp}
              />
            ))}
            <BackToLive show={showBackToTop} onClick={backToLive} />
          </>
        </InfiniteScroll>
      ) : (
        <>
          {!loading && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex w-auto h-auto items-center justify-center flex-col">
                <SvgIcon
                  svg={
                    <NewWindowPlaceholder className="w-36 h-auto fill-gray-400" />
                  }
                />
                <Text
                  text="Utilizza la barra in alto per iniziare una nuova ricerca"
                  className="mt-2 text-gray-400"
                />
              </div>
            </div>
          )}
        </>
      )}
      <LoadingTab loading={loading} />
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
