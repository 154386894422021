import { createSelector } from '@reduxjs/toolkit';
import { WorkingAreasState } from './reducers';
import { ReduxState } from '../index';
import { isFilter } from './utils';
import { WindowPair } from '@nwa/graphql';

export const workingAreasSelector = createSelector(
  (state: ReduxState): WorkingAreasState => state.workingAreasReducer,
  (state) => state
);

export const numberOfFilter = createSelector(
  [
    // Usual first input - extract value from `state`
    (state) => state.workingAreasReducer,
    // Take the second arg, `category`, and forward to the output selector
    (state1, id) => id,
  ],
  // Output selector gets (`items, category)` as args
  (state, id) => {
    const filterDate = !!state.dateRange.find((item: any) => item.id === id)
      ? 1
      : 0;
    const filterItem = state.draftWorkingArea?.windows.find(
      (item: WindowPair) => item.id === id
    );
    const filterProvider = filterItem?.value.search?.providers?.length;
    const filterSentiment = filterItem?.value.search?.sentiments?.length;
    const filterContentType = filterItem?.value.search?.contentTypes?.length;
    const filterKeywords = filterItem?.value.search?.keywords?.length;

    return (
      isFilter(filterProvider) +
      isFilter(filterSentiment) +
      isFilter(filterContentType) +
      isFilter(filterKeywords) +
      filterDate
    );
  }
);

export const hasFullTextFilter = createSelector(
  [
    // Usual first input - extract value from `state`
    (state) => state.workingAreasReducer,
    // Take the second arg, `category`, and forward to the output selector
    (state1, id) => id,
  ],
  // Output selector gets (`items, category)` as args
  (state, id) => {
    const filterItem = state.draftWorkingArea?.windows.find(
      (item: WindowPair) => item.id === id
    );
    const filterText = filterItem?.value.search?.fullSearchText?.length;

    return isFilter(filterText) === 1;
  }
);
