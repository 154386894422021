import { gql } from '@apollo/client/core';
import { gqlPressDetailShape } from './shapes';

export const ADD_BOOKMARK = gql`
  mutation AddBookmark($id: ID!, $color: BookmarkColor) {
    addBookmark(id: $id, color: $color) {
      _
    }
  }
`;

export const ADD_NEWS_TO_CURRENT_PRESS = gql`
  mutation AddNewsToCurrentPress($to: PressType!, $newsId: [ID!]!) {
    addNewsToCurrentPress(to: $to, newsId: $newsId) {
      _
    }
  }
`;

export const REMOVE_NEWS_FROM_CURRENT_PRESS = gql`
  mutation RemoveNewsFromCurrentPress($to: PressType!, $newsId: [ID!]!) {
    removeNewsFromCurrentPress(to: $to, newsId: $newsId) {
      _
    }
  }
`;

export const DELETE_BOOKMARK = gql`
  mutation DeleteBookmark($id: ID!) {
    deleteBookmark(id: $id) {
      _
    }
  }
`;

export const DELETE_BOOKMARKS = gql`
  mutation DeleteBookmarks($color: BookmarkColor!) {
    deleteBookmarks(color: $color) {
      _
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      _
    }
  }
`;

export const DELETE_KEYWORD = gql`
  mutation DeleteKeyword($id: ID!) {
    deleteKeyword(id: $id) {
      _
    }
  }
`;

export const DELETE_WORKING_AREA = gql`
  mutation DeleteWorkingArea($id: ID!) {
    deleteWorkingArea(id: $id) {
      _
    }
  }
`;

export const DRAFT_PRESS = gql`
  mutation DraftPress($id: ID!, $detail: PressDetailInput!) {
    draftPress(id: $id, detail: $detail) {
      ${gqlPressDetailShape}
    }
  }
`;

export const NEW_PRESS_AI = gql`
  mutation NewPressAI($input: PressAIInput!) {
    newPressAI(input: $input)
  }
`;

export const UPDATE_PRESS_AI = gql`
  mutation UpdatePressAI($id: ID!, $description: String!, $detail: PressDetailInput!) {
    updatePressAI(id: $id, description: $description, detail: $detail){
      ${gqlPressDetailShape}
    }
  }
`;

export const NEW_CURRENT_PRESS = gql`
  mutation NewCurrentPress($type: PressType!, $detail: PressDetailInput!) {
    newCurrentPress(type: $type, detail: $detail)
  }
`;

export const NEW_CATEGORY = gql`
  mutation NewCategory($category: CategoryInput!) {
    newCategory(category: $category)
  }
`;

export const NEW_KEYWORD = gql`
  mutation NewKeyword($keyword: KeywordInput!) {
    newKeyword(keyword: $keyword)
  }
`;

export const NEW_WORKING_AREA = gql`
  mutation NewWorkingArea($workingArea: WorkingAreaInput!) {
    newWorkingArea(workingArea: $workingArea)
  }
`;

export const SAVE_PRESS = gql`
  mutation SavePress($id: ID!, $detail: PressDetailInput!) {
    savePress(id: $id, detail: $detail) {
      _
    }
  }
`;

export const SET_CATEGORY_TO_NEWS = gql`
  mutation SetCategoryToNews($newsId: ID!, $categoryId: ID!) {
    setCategoryToNews(newsId: $newsId, categoryId: $categoryId) {
      _
    }
  }
`;

export const UNSET_CATEGORY_TO_NEWS = gql`
  mutation UnsetCategoryToNews($newsId: ID!) {
    unsetCategoryToNews(newsId: $newsId) {
      _
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $category: CategoryInput!) {
    updateCategory(id: $id, category: $category) {
      _
    }
  }
`;

export const UPDATE_KEYWORD = gql`
  mutation UpdateKeyword($id: ID!, $keyword: KeywordInput!) {
    updateKeyword(id: $id, keyword: $keyword) {
      _
    }
  }
`;

export const UPDATE_WORKING_AREA = gql`
  mutation UpdateWorkingArea($id: ID!, $workingArea: WorkingAreaInput!) {
    updateWorkingArea(id: $id, workingArea: $workingArea) {
      _
    }
  }
`;

export const MARK_NEWS_READ = gql`
  mutation MarkNewsRead($id: ID!) {
    markAsRead(id: $id) {
      _
    }
  }
`;

export const DELETE_PRESSES = gql`
  mutation DeletePresses($ids: [ID!]!) {
    deletePresses(ids: $ids) {
      _
    }
  }
`;

export const ADD_NEWS_FROM_OLD_PRESSES = gql`
  mutation AddNewsFromOldPresses($from: [ID!]!, $to: ID!) {
    addNewsFromOldPresses(from: $from, to: $to) {
      _
    }
  }
`;

export const PING = gql`
  mutation Ping {
    ping {
      _
    }
  }
`;
