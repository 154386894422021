import {
  DateRange,
  Environment,
  Keyword,
  SavedSearch,
  WindowPair,
  WindowType,
  WorkingArea,
} from '@nwa/graphql';
import { createReducer, current } from '@reduxjs/toolkit';
import {
  addNewWindow,
  moveWorkingAreaInDraft,
  removeDateRange,
  removeWindow,
  setCurrentWorkingAreaIsModified,
  setConfigDraftWorkingArea,
  setEnvironment,
  setWorkingAreaFilter,
  openNewWindow,
  setSelectedWindow,
  setWorkingAreas,
  removeDraftWorkingArea,
  updateWorkingArea,
  deleteWorkingArea,
  setIdDraftWorkingArea,
  setNameDraftWorkingArea,
  setFirstWorkingAreaLoaded,
  setWindowCount,
  backupWorkingAreaFilterAndSetTypeBookmark,
  restoreBackupWorkingAreaFilter,
  removeBackupWorkingAreaFilter,
  filterWorkingAreas,
  resetWorkingAreaFilter,
} from './actions';
import { v4 as uuidv4 } from 'uuid';
import { LayoutBase } from 'rc-dock';

export interface WorkingAreasState {
  workingAreas: WorkingArea[];
  currentWorkingAreaIsModified: boolean;
  isOpenedNewWindow?: WindowType;
  selectedWindow?: string;
  selectedWorkingArea?: string;
  draftWorkingArea?: WorkingArea;
  dateRange: { id: string; date: DateRange }[];
  dateRangeBackups: { id: string; date: DateRange }[];
  firstWorkingAreaLoaded: boolean;
  windowCounts: { [key: string]: number };
  backups: { [key: string]: SavedSearch };
  resetWorkingAreaFilter: boolean;
}

const defaultState: WorkingAreasState = {
  workingAreas: [],
  currentWorkingAreaIsModified: false,
  dateRange: [],
  dateRangeBackups: [],
  firstWorkingAreaLoaded: false,
  windowCounts: {},
  backups: {},
  resetWorkingAreaFilter: false,
};

export const workingAreasReducer = createReducer<WorkingAreasState>(
  defaultState,
  {
    [filterWorkingAreas.type]: (state, action) => {
      let workingAreas: WorkingArea[] = [];
      current(state).workingAreas.forEach((workingArea) => {
        let thisWorkingArea = { ...workingArea };
        let windows: WindowPair[] = [];
        workingArea.windows.forEach((window) => {
          let thisWindow = { ...window };
          if (thisWindow.value.search && thisWindow.value.search.keywords)
            thisWindow = {
              ...thisWindow,
              value: {
                ...thisWindow.value,
                search: {
                  ...thisWindow.value.search,
                  keywords: thisWindow.value.search.keywords.filter((keyword) =>
                    action.payload.some(
                      (k: Keyword) => k.expression === keyword
                    )
                  ),
                },
              },
            };
          windows.push(thisWindow);
        });
        workingAreas.push({ ...thisWorkingArea, windows });
      });
      return {
        ...state,
        workingAreas,
      };
    },
    [backupWorkingAreaFilterAndSetTypeBookmark.type]: (state, action) => {
      const currentWindow = current(state).draftWorkingArea?.windows.find(
        (window) => window.id === current(state).selectedWindow
      );
      let backups = { ...current(state).backups };
      backups[current(state).selectedWindow!] = {
        contentTypes: currentWindow?.value.search?.contentTypes,
        fullSearchText: currentWindow?.value.search?.fullSearchText,
        keywords: currentWindow?.value.search?.keywords,
        providers: currentWindow?.value.search?.providers,
        sentiments: currentWindow?.value.search?.sentiments,
        environment: currentWindow?.value.search?.environment,
      };
      const newWindows = [
        ...(current(state).draftWorkingArea?.windows || []).filter(
          (window) => window.id !== current(state).selectedWindow
        ),
        {
          ...currentWindow,
          id: currentWindow!.id,
          value: {
            type: WindowType.BOOKMARK,
            color: action.payload,
            search: {},
          },
        },
      ];

      let dateRangeBackups = [...current(state).dateRangeBackups];
      const dateRangeBackup = current(state).dateRange.find(
        (w) => w.id === current(state).selectedWindow
      );
      if (dateRangeBackup) dateRangeBackups.push(dateRangeBackup);

      return {
        ...state,
        draftWorkingArea: {
          ...state.draftWorkingArea!,
          windows: newWindows,
        },
        backups,
        dateRange: state.dateRange.filter((w) => w.id !== state.selectedWindow),
        dateRangeBackups,
      };
    },
    [restoreBackupWorkingAreaFilter.type]: (state, action) => {
      const currentWindow = current(state).draftWorkingArea?.windows.find(
        (window) => window.id === current(state).selectedWindow
      );
      const newWindows = [
        ...(current(state).draftWorkingArea?.windows || []).filter(
          (window) => window.id !== current(state).selectedWindow
        ),
        {
          ...currentWindow,
          id: currentWindow!.id,
          value: {
            type: WindowType.SEARCH,
            search: current(state).backups[action.payload],
          },
        },
      ];

      let dateRange = [...current(state).dateRange];
      const dateRangeBackup = current(state).dateRangeBackups.find(
        (w) => w.id === action.payload
      );
      if (dateRangeBackup) dateRange.push(dateRangeBackup);

      return {
        ...state,
        draftWorkingArea: {
          ...state.draftWorkingArea!,
          windows: newWindows,
        },
        backups: Object.keys(state.backups)
          .filter((key) => key !== action.payload)
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: state.backups[key] });
          }, {}),
        dateRangeBackups: state.dateRangeBackups.filter(
          (w) => w.id !== state.selectedWindow
        ),
        dateRange,
      };
    },
    [removeBackupWorkingAreaFilter.type]: (state, action) => ({
      ...state,
      backups: Object.keys(state.backups)
        .filter((key) => key !== action.payload)
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: state.backups[key] });
        }, {}),
      dateRangeBackups: state.dateRangeBackups.filter(
        (w) => w.id !== state.selectedWindow
      ),
    }),
    [setWorkingAreas.type]: (state, action): WorkingAreasState => ({
      ...state,
      workingAreas: action.payload,
    }),
    [setWindowCount.type]: (state, action): WorkingAreasState => {
      let windowCounts = { ...current(state).windowCounts };
      windowCounts[action.payload.id] = action.payload.count;
      return {
        ...state,
        windowCounts,
      };
    },
    [removeDraftWorkingArea.type]: (state): WorkingAreasState => {
      const id = uuidv4();
      const windowsId = uuidv4();

      const layout: LayoutBase = {
        dockbox: {
          mode: 'horizontal',
          children: [
            {
              mode: 'vertical',
              children: [
                {
                  tabs: [
                    {
                      id: `${windowsId}`,
                    },
                  ],
                },
              ],
            },
          ],
        },
      };

      return {
        ...state,
        selectedWindow: windowsId,
        draftWorkingArea: {
          creationDate: new Date().getTime() as unknown as Date,
          id: id,
          name: '',
          windows: [
            {
              id: windowsId,
              value: {
                type: WindowType.SEARCH,
                search: { environment: Environment.GLOBALE_GENERALE },
              },
            },
          ],
          config: layout,
        },
        windowCounts: {},
      };
    },
    [setCurrentWorkingAreaIsModified.type]: (
      state,
      action
    ): WorkingAreasState => ({
      ...state,
      currentWorkingAreaIsModified: action.payload,
      selectedWindow: undefined,
      workingAreas: action.payload,
    }),
    [setSelectedWindow.type]: (state, action): WorkingAreasState => ({
      ...state,
      selectedWindow: action.payload,
    }),
    [openNewWindow.type]: (state, action): WorkingAreasState => ({
      ...state,
      isOpenedNewWindow: action.payload,
    }),
    [setConfigDraftWorkingArea.type]: (state, action): WorkingAreasState => {
      const id = uuidv4();

      return {
        ...state,
        draftWorkingArea: {
          creationDate:
            state.draftWorkingArea?.creationDate ||
            (new Date().getTime() as unknown as Date),
          id: state.draftWorkingArea?.id || id,
          name: state.draftWorkingArea?.name || '',
          windows: state.draftWorkingArea?.windows || [],
          config: action.payload,
        },
        windowCounts: {},
      };
    },
    [moveWorkingAreaInDraft.type]: (state, action): WorkingAreasState => {
      const newDraft = current(state).workingAreas.find(
        (w) => w.id === action.payload
      );

      if (newDraft)
        return {
          ...state,
          draftWorkingArea: newDraft,
          selectedWorkingArea: newDraft?.id,
          windowCounts: {},
        };
      else return state;
    },
    [setIdDraftWorkingArea.type]: (state, action): WorkingAreasState => {
      return {
        ...state,
        draftWorkingArea: {
          ...state.draftWorkingArea,
          id: action.payload,
        } as WorkingArea,
        selectedWorkingArea: action.payload,
      };
    },
    [setNameDraftWorkingArea.type]: (state, action): WorkingAreasState => {
      return {
        ...state,
        draftWorkingArea: {
          ...state.draftWorkingArea,
          name: action.payload,
        } as WorkingArea,
      };
    },
    [removeDateRange.type]: (state): WorkingAreasState => ({
      ...state,
      dateRange: state.dateRange.filter((w) => w.id !== state.selectedWindow),
    }),
    [addNewWindow.type]: (state, action): WorkingAreasState => {
      const newWindows = [
        ...(current(state).draftWorkingArea?.windows || []),
        {
          id: action.payload.id,
          value: {
            search: { environment: Environment.GLOBALE_GENERALE },
            type: current(state).isOpenedNewWindow! || WindowType.SEARCH,
          },
        },
      ];

      return {
        ...state,
        draftWorkingArea: {
          ...state.draftWorkingArea!,
          windows: newWindows,
        },
      };
    },
    [removeWindow.type]: (state, action): WorkingAreasState => {
      const newWindows =
        current(state).draftWorkingArea?.windows.filter(
          (window) => window.id !== action.payload.id
        ) || [];

      const selectedWindow =
        current(state).selectedWindow === action.payload.id;

      return {
        ...state,
        selectedWindow: selectedWindow ? undefined : state.selectedWindow,
        dateRange: state.dateRange.filter((w) => w.id !== action.payload.id),
        draftWorkingArea: {
          ...state.draftWorkingArea!,
          windows: newWindows,
        },
      };
    },
    [setEnvironment.type]: (state, action): WorkingAreasState => {
      const windowsMap = current(state.draftWorkingArea!).windows.map(
        (window) => {
          if (window.id === state.selectedWindow) {
            return {
              ...window,
              value: {
                type: WindowType.SEARCH,
                search: {
                  ...window.value.search,
                  environment: action.payload.environment,
                },
              },
            };
          }
          return window;
        }
      );

      return {
        ...state,

        dateRange: state.dateRange.filter((w) => w.id !== action.payload.id),
        draftWorkingArea: {
          ...state.draftWorkingArea!,
          windows: windowsMap,
        },
      };
    },
    [setWorkingAreaFilter.type]: (state, action): WorkingAreasState => {
      const windowsMap = current(state.draftWorkingArea!).windows.map(
        (window) => {
          if (window.id === state.selectedWindow) {
            return {
              ...window,
              value: {
                ...window.value,
                search: {
                  environment: window.value.search!.environment,
                  contentTypes: action.payload.contentTypes,
                  fullSearchText: action.payload.fullSearchText,
                  keywords: action.payload.keywords
                    .filter((keyword: Keyword) => keyword && keyword.expression)
                    .map((keyword: Keyword) => keyword.expression),
                  providers: action.payload.providers,
                  sentiments: action.payload.sentiments,
                },
              },
            };
          }
          return window;
        }
      );

      const newDateRange = action.payload.dateRange
        ? [
            ...current(state).dateRange.filter(
              (dr) => dr.id !== current(state).selectedWindow
            ),
            {
              id: current(state).selectedWindow!,
              date: action.payload.dateRange,
            },
          ]
        : current(state).dateRange;

      return {
        ...state,
        dateRange: newDateRange,
        draftWorkingArea: {
          ...state.draftWorkingArea!,
          windows: windowsMap,
        },
      };
    },
    [updateWorkingArea.type]: (state, action): WorkingAreasState => {
      const worAr = current(state.workingAreas!).map((w) => {
        return w.id === action.payload.id
          ? {
              ...w,
              name: action.payload.workingArea.name,
              windows: action.payload.workingArea.windows,
              config: action.payload.workingArea.config,
            }
          : w;
      });

      return {
        ...state,
        workingAreas: worAr,
      };
    },
    [deleteWorkingArea.type]: (state): WorkingAreasState => {
      return {
        ...state,
        workingAreas: state.workingAreas!.filter(
          (w) => w.id !== state.selectedWorkingArea
        ),
      };
    },
    [setFirstWorkingAreaLoaded.type]: (state, action): WorkingAreasState => {
      return {
        ...state,
        firstWorkingAreaLoaded: action.payload,
      };
    },
    [resetWorkingAreaFilter.type]: (state): WorkingAreasState => {
      return {
        ...state,
        resetWorkingAreaFilter: !state.resetWorkingAreaFilter,
      };
    },
  }
);
