import React, { useState, FC, useEffect } from 'react';

interface PercentInputProps {
  onInvalidInput: (message: string) => void;
  onValueChange: (newValue: string) => void;
  selected?: boolean; // Aggiunta della prop selected per controllare il comportamento del placeholder %
}

export const PercentInput: FC<PercentInputProps> = ({
  onInvalidInput,
  onValueChange,
  selected = false, // Default a false se non specificato
}) => {
  const min = 1;
  const max = 100;
  const [currentValue, setCurrentValue] = useState(`${min}`);

  useEffect(() => {
    // Se selected è true, reimposta il valore iniziale a "1"%
    if (selected) {
      setCurrentValue(`${min}`);
    } else {
      // Se selected è false, pulisce il campo
      setCurrentValue('');
    }
  }, [selected]); // Dipendenze dell'effetto: si ricalcola solo se 'selected' cambia

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(event.target.value);
    setCurrentValue(event.target.value);

    // Gestione della conversione e validazione del valore numerico
    const numericValue = Number(event.target.value);
    if (isNaN(numericValue)) {
      onInvalidInput('Il numero inserito non è valido.');
      return;
    }
    if (numericValue < min || numericValue > max) {
      onInvalidInput(
        `La percentuale deve essere compresa tra ${min} e ${max}.`
      );
      return;
    }
    // Chiamata alla funzione onInvalidInput con stringa vuota per indicare che non ci sono errori
    onInvalidInput('');
  };

  return (
    <input
      type="number"
      value={currentValue}
      onChange={handleChange}
      min={min}
      max={max}
      className="border-gray-300 p-2 rounded w-16 h-8 ml-4 border focus:ring-blue-500 focus:border-blue-500"
      aria-label="Percent Input"
    />
  );
};
