import React, { useState, useEffect } from 'react';

interface NumberInputProps {
  min: number;
  max: number;
  onInvalidInput: (message: string) => void;
  onValueChange: (newValue: string) => void;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  min,
  max,
  onInvalidInput,
  onValueChange,
}) => {
  const [value, setValue] = useState(max.toString());

  useEffect(() => {
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      onInvalidInput('Inserisci un numero valido.');
      return;
    }

    if (numericValue < min || numericValue > max) {
      onInvalidInput(`Il numero deve essere compreso tra ${min} e ${max}.`);
      return;
    }

    onInvalidInput('');
    onValueChange(value);
  }, [value, onInvalidInput, onValueChange, min, max]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      min={min}
      max={max}
      className="border-gray-300 p-2 rounded w-14 h-8 ml-4 border focus:ring-blue-500 focus:border-blue-500"
    />
  );
};
