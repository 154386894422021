import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { useNotificationBanner } from './useNotification';

export const useMutationHook = ({
  errorMessage,
  successMessage,
  queryGql,
  disableSuccessMessage,
}: {
  queryGql: DocumentNode;
  successMessage?: string;
  errorMessage?: string;
  disableSuccessMessage?: boolean;
}): any => {
  const { t } = useTranslation();
  const { dispatchNotificationBanner } = useNotificationBanner();
  const [mutationFunction, { loading, error, data }] = useMutation(queryGql, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      // Verifica se l'errore contiene il messaggio specifico per "nessuna news trovata"
      const isNoNewsFoundError = error.graphQLErrors.some(
        (err) => err.message === 'No news found for the given filters'
      );
      // Verifica categoria errata
      // Invalid categories: categoria 1, categoria 2
      // Messaggio di errore con l'elenco delle categorie, if error message contains "Invalid categories:"
      // write error with the list of categories after the colon
      const isWrongCategoryError = error.graphQLErrors.some((err) =>
        err.message.includes('Invalid categories:')
      );
      // Se l'errore è specifico per nessuna news trovata, mostra un messaggio personalizzato
      if (isNoNewsFoundError) {
        dispatchNotificationBanner({
          text: t('Non sono presenti notizie per i filtri selezionati'), // Usa il tuo messaggio personalizzato qui
          ok: false,
          title: t('Attenzione'), // Personalizza il titolo se necessario
        });
      } else if (isWrongCategoryError) {
        const invalidCategories = error.graphQLErrors[0].message.split(':')[1];
        dispatchNotificationBanner({
          text: t(
            'Le seguenti categorie non sono valide: ' + invalidCategories
          ), // Usa il tuo messaggio personalizzato qui
          ok: false,
          title: t('Attenzione'), // Personalizza il titolo se necessario
        });
      } else {
        // Altrimenti, mostra il messaggio d'errore generico
        dispatchNotificationBanner({
          text: t(errorMessage || 'errorGenericMessage'),
          ok: false,
          title: t('errorGenericTitle'),
        });
      }
    }
    if (data && !disableSuccessMessage) {
      dispatchNotificationBanner({
        text: successMessage ? t(successMessage) : undefined,
        ok: true,
        title: t('operationSuccessfully'),
      });
    }
    // eslint-disable-next-line
  }, [error, data]);

  return [mutationFunction, { data, loading, error }];
};
