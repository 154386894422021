import Modal from '../Modal';
import {
  removeCategory,
  setCategories,
} from '../../redux/filterChoices/actions';
import { Button } from '../Button';
import { Text } from '../Text';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificationBanner } from '../hooks/useNotification';
import { DELETE_CATEGORY, UPDATE_CATEGORY } from '../../graphql/news/mutation';
import { useMutationHook } from '../hooks/useMutationHook';
import { CategoryDraggableCard } from './CategoryDraggableCard';
import { Category } from '@nwa/graphql';
import update from 'immutability-helper';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { useInitialFillTags } from '../hooks/useInitialFillTheFilterChoices';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DraggableContainerTag } from './DraggableContainerTag';
import { DraggableTagList } from './DraggableTagList';
import { DraggableContainerKeyword } from './DraggableContainerKeyword';
import { DraggableKeywordList } from './DraggableKeywordList';

interface CategoryModalManageProps {
  setShowManageModal: (newValue: boolean) => void;
}

export const CategoryModalManage: FC<CategoryModalManageProps> = ({
  setShowManageModal,
}) => {
  const [updateCategoryMutation] = useMutationHook({
    queryGql: UPDATE_CATEGORY,
  });
  const [deleteCategoryMutation] = useMutationHook({
    queryGql: DELETE_CATEGORY,
  });

  useInitialFillTags();

  const { categories, tags, keywords } = useSelector(filterChoicesSelector);
  // console.log('categories', categories);
  const dispatch = useDispatch();
  const { dispatchNotificationBanner } = useNotificationBanner();

  const [showEditModal, setShowEditModal] = useState(false);
  const emptyCategory: Category = {
    id: '',
    name: '',
    position: 0,
    fallback: false,
    tags: [],
    keywords: [],
  };
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const [categoriesDnd, setCategoriesDnd] = useState(categories);
  const [categoriesDndBkp, setCategoriesDndBkp] = useState(categories);
  useEffect(() => {
    setCategoriesDnd(categories);
    setCategoriesDndBkp(categories);
  }, [categories]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCategoriesDnd((prevCategories: Category[]) =>
      update(prevCategories, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCategories[dragIndex] as Category],
        ],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAllPosition = useCallback(async () => {
    await categoriesDnd.forEach((category, index) => {
      updateCategoryMutation({
        variables: {
          id: category.id,
          category: {
            name: category.name,
            position: index,
            fallback: category.fallback,
            tags: category.tags.map((t) => t.id),
            keywords: category.keywords.map((k) => k.id),
          },
        },
      });
    });
    dispatch(setCategories(categoriesDnd));
    setCategoriesDndBkp(categoriesDnd);
    setShowManageModal(false);
  }, [categoriesDnd, dispatch, setShowManageModal, updateCategoryMutation]);

  const saveCategories = () => {
    const _saveCategoriesDnd = (
      newCurrentCategory: Category | undefined,
      updateCategory: boolean
    ) => {
      if (newCurrentCategory) {
        setCategoriesDnd((categoriesDnd) => {
          const newCategoriesDnd = [...categoriesDnd, newCurrentCategory];
          if (updateCategory) {
            updateCategoryMutation({
              variables: {
                id: currentCategory!.id,
                category: {
                  name: currentCategory!.name,
                  position: currentCategory!.position,
                  fallback: currentCategory!.fallback,
                  tags: currentCategory!.tags.map((t) => t.id),
                  keywords: currentCategory!.keywords.map((k) => k.id),
                },
              },
            }).then(() => {
              _saveCategories(newCategoriesDnd);
            });
          } else {
            _saveCategories(newCategoriesDnd);
          }
          return newCategoriesDnd;
        });
      } else {
        setCategoriesDnd((prevCategories: Category[]) => {
          const index = categoriesDnd.findIndex(
            (category) => category.id === currentCategory!.id
          );
          const newCategoriesDnd = update(prevCategories, {
            $splice: [[index, 1, currentCategory as Category]],
          });
          if (updateCategory) {
            updateCategoryMutation({
              variables: {
                id: currentCategory!.id,
                category: {
                  name: currentCategory!.name,
                  position: currentCategory!.position,
                  fallback: currentCategory!.fallback,
                  tags: currentCategory!.tags.map((t) => t.id),
                  keywords: currentCategory!.keywords.map((k) => k.id),
                },
              },
            }).then(() => {
              _saveCategories(newCategoriesDnd);
            });
          } else {
            _saveCategories(newCategoriesDnd);
          }
          return newCategoriesDnd;
        });
      }
    };

    const _saveCategories = (newCategoriesDnd: Category[] | undefined) => {
      dispatch(setCategories(newCategoriesDnd || categoriesDnd || []));
      setCategoriesDndBkp(newCategoriesDnd || categoriesDnd || []);
      setShowManageModal(false);
      setShowEditModal(false);
      setCurrentCategory(null);
    };

    if (currentCategory) {
      if (currentCategory.name.trim() === '') {
        dispatchNotificationBanner({
          title: 'Errore',
          text: 'Si prega di compilare i campi richiesti (*)',
          ok: false,
        });
        return;
      }
      _saveCategoriesDnd(undefined, true);
    } else _saveCategories(undefined);
  };

  const removeCategoryFc = (id: string) => {
    deleteCategoryMutation({
      variables: {
        id,
      },
    }).then(() => {
      dispatch(removeCategory(id));
    });
  };

  const editCategory = (id: string) => {
    setCurrentCategory(categories.find((k) => k.id === id) || emptyCategory);
    setShowEditModal(true);
  };

  const undo = (isEdit: boolean) => {
    setCategoriesDnd(categoriesDndBkp);
    setShowManageModal(isEdit);
    setShowEditModal(!isEdit);
  };

  const otherTags = useMemo(
    () =>
      tags.filter(
        (t) =>
          currentCategory &&
          currentCategory.tags.findIndex((ct) => ct.id === t.id) < 0
      ),
    [currentCategory, tags]
  );

  const otherKeywords = useMemo(
    () =>
      keywords.filter(
        (k) =>
          currentCategory &&
          currentCategory.keywords.findIndex((ck) => ck.id === k.id) < 0
      ),
    [currentCategory, keywords]
  );

  const addKeywordToCategory = (keywordId: string, category: Category) => {
    const keyword = keywords.find((k) => k.id === keywordId);
    if (keyword) {
      return {
        ...category,
        keywords: [...category.keywords, keyword],
      };
    }
    return category;
  };

  const removeKeywordFromCategory = (keywordId: string, category: Category) => {
    return {
      ...category,
      keywords: category.keywords.filter((k) => k.id !== keywordId),
    };
  };

  const onDragEnd = useCallback(
    (result: any) => {
      // console.log('onDragEnd', result);
      if (!result.destination) {
        return;
      }

      if (
        result.destination.index === result.source.index &&
        result.source.droppableId === result.destination.droppableId
      ) {
        return;
      }

      if (result.type === 'tag') {
        if (result.source.droppableId !== result.destination.droppableId) {
          if (result.destination.droppableId === 'othertags') {
            setCurrentCategory((currentCategory) => {
              if (currentCategory) {
                return {
                  ...currentCategory,
                  tags: currentCategory.tags.filter(
                    (t) => t.id !== result.draggableId
                  ),
                };
              }
              return null;
            });
          } else {
            setCurrentCategory((currentCategory) => {
              if (currentCategory) {
                // console.log(result.draggableId, tags);
                const tag = tags.find(
                  (t) => t.id.toString() === result.draggableId
                ) || {
                  id: -1,
                  description: 'NOT FOUND',
                };
                return {
                  ...currentCategory,
                  tags: [
                    ...currentCategory.tags.slice(0, result.destination.index),
                    tag,
                    ...currentCategory.tags.slice(result.destination.index),
                  ],
                };
              }
              return null;
            });
          }
        }
      } else if (result.type === 'keyword') {
        if (result.source.droppableId !== result.destination.droppableId) {
          setCurrentCategory((currentCategory) => {
            if (currentCategory) {
              if (result.destination.droppableId === 'otherkeywords') {
                return removeKeywordFromCategory(
                  result.draggableId,
                  currentCategory
                );
              } else {
                return addKeywordToCategory(
                  result.draggableId,
                  currentCategory
                );
              }
            }
            return null;
          });
        }
      }
    },
    [tags, keywords]
  );

  const fallbackOn = (id: string) => {
    const index = categoriesDnd.findIndex((c) => c.id === id);
    const category = {
      ...categoriesDnd[index],
      fallback: true,
    };
    const indexPrevFallbackCategory = categoriesDnd.findIndex(
      (c) => c.fallback
    );
    if (indexPrevFallbackCategory > -1) {
      const prevFallbackCategory = {
        ...categoriesDnd[indexPrevFallbackCategory],
        fallback: false,
      };
      setCategoriesDnd((prevCategories: Category[]) =>
        update(prevCategories, {
          $splice: [
            [index, 1, category],
            [indexPrevFallbackCategory, 1, prevFallbackCategory],
          ],
        })
      );
    } else {
      setCategoriesDnd((prevCategories: Category[]) =>
        update(prevCategories, {
          $splice: [[index, 1, category]],
        })
      );
    }
  };

  // Print categoriesDnd
  // console.log('categoriesDnd', categoriesDnd);
  return (
    <>
      {!showEditModal && (
        <Modal
          title={'Gestisci categorie'}
          showCloseButton={true}
          onClickCloseButton={() => setShowManageModal(false)}
          footer={
            <>
              <Button color="secondary" onClick={() => undo(false)}>
                <Text text="Annulla" />
              </Button>
              <Button
                color="primary"
                className="ml-4"
                onClick={() => saveAllPosition()}
              >
                <Text text="Salva" />
              </Button>
            </>
          }
        >
          <div>
            {categoriesDnd.map((category, i) => (
              <CategoryDraggableCard
                key={category.id}
                index={i}
                id={category.id}
                text={category.name}
                fallback={category.fallback}
                moveCard={moveCard}
                editCard={editCategory}
                removeCard={removeCategoryFc}
                fallbackOn={fallbackOn}
              />
            ))}
          </div>
        </Modal>
      )}
      {showEditModal && (
        <Modal
          title={'Modifica categoria'}
          showCloseButton={true}
          onClickCloseButton={() => setShowEditModal(false)}
          footer={
            <>
              <Button color="secondary" onClick={() => undo(true)}>
                <Text text="Annulla" />
              </Button>
              <Button
                color="primary"
                className="ml-4"
                onClick={() => saveCategories()}
              >
                <Text text="Salva" />
              </Button>
            </>
          }
        >
          <div className="flex flex-row">
            {/*First drag component-> Topics*/}
            <div className="flex flex-col mr-1" style={{ width: '450px' }}>
              <div className="overflow-y-auto scrollbar w-full">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="tags" type="tag">
                    {(providedDropTag) => (
                      <div
                        ref={providedDropTag.innerRef}
                        {...providedDropTag.droppableProps}
                        className="flex-col max-h-80 overflow-y-auto scrollbar w-full"
                      >
                        <DraggableContainerTag
                          category={currentCategory?.name || ''}
                          currentTagDraggable={currentCategory?.tags || []}
                          onEditCategory={(name: string) =>
                            setCurrentCategory({ ...currentCategory!, name })
                          }
                          onClickRemoveTag={(id: number) =>
                            setCurrentCategory((currentCategory) => {
                              if (currentCategory) {
                                return {
                                  ...currentCategory,
                                  tags: currentCategory.tags.filter(
                                    (t) => t.id !== id
                                  ),
                                };
                              }
                              return null;
                            })
                          }
                        />
                        {providedDropTag.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="othertags" type="tag">
                    {(providedDropTag) => (
                      <div
                        ref={providedDropTag.innerRef}
                        {...providedDropTag.droppableProps}
                        className="flex-col max-h-80 overflow-y-auto scrollbar w-full mt-2"
                      >
                        {otherTags.map((tag, i) => (
                          <DraggableTagList
                            key={tag.id + 'outer'}
                            index={i}
                            id={tag.id}
                            title={tag.description}
                            showRemove={false}
                          />
                        ))}
                        {providedDropTag.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            {/*Second drag component-> Keywords*/}
            <div className="flex flex-col ml-1" style={{ width: '450px' }}>
              <div className="overflow-y-auto scrollbar w-full">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="keywords" type="keyword">
                    {(providedDropKeyword) => (
                      <div
                        ref={providedDropKeyword.innerRef}
                        {...providedDropKeyword.droppableProps}
                        className="flex-col max-h-80 overflow-y-auto scrollbar w-full"
                      >
                        <DraggableContainerKeyword
                          category={currentCategory?.name || ''}
                          currentKeywordDraggable={
                            currentCategory?.keywords || []
                          }
                          onEditCategory={(name: string) =>
                            setCurrentCategory({ ...currentCategory!, name })
                          }
                          onClickRemoveKeyword={(id: string) =>
                            setCurrentCategory((currentCategory) => {
                              if (currentCategory) {
                                return {
                                  ...currentCategory,
                                  keywords: currentCategory.keywords.filter(
                                    (t) => t.id !== id
                                  ),
                                };
                              }
                              return null;
                            })
                          }
                        />
                        {providedDropKeyword.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="otherkeywords" type="keyword">
                    {(providedDropKeyword) => (
                      <div
                        ref={providedDropKeyword.innerRef}
                        {...providedDropKeyword.droppableProps}
                        className="flex-col max-h-80 overflow-y-auto scrollbar w-full mt-2"
                      >
                        {otherKeywords.map((keyword, i) => (
                          <DraggableKeywordList
                            key={keyword.id + 'outer'}
                            index={i}
                            id={keyword.id}
                            title={keyword.name}
                            showRemove={false}
                          />
                        ))}
                        {providedDropKeyword.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
