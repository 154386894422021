import React from 'react';

// Props del componente
interface CategoryDropdownProps {
  categories: string[]; // Array di categorie
  onSelect: (categoria: string) => void; // Funzione callback per la selezione di una categoria
}

export const CategoryDropdown: React.FC<CategoryDropdownProps> = ({
  categories,
  onSelect,
}) => {
  return (
    <>
      {categories.length > 0 ? (
        categories.map((categoria, index) => (
          <div
            key={index}
            className="p-2 hover:bg-gray-100 cursor-pointer hover:bg-slate-200"
            onClick={() => onSelect(categoria)}
          >
            {categoria}
          </div>
        ))
      ) : (
        <div className="p-2">Nessuna categoria trovata</div>
      )}
    </>
  );
};

export default CategoryDropdown;
