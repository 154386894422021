import { FC } from 'react';
import { Text } from '../Text';
import { SvgIcon } from '../SvgIcon';
import { DropHere } from '../../svg';
import { Keyword } from '@nwa/graphql';
import { DraggableKeywordList } from './DraggableKeywordList';

export interface KeywordDraggableProps {
  currentKeywordDraggable: Keyword[];
  category: string;
  onEditCategory: (name: string) => void;
  onClickRemoveKeyword: (id: string) => void;
}

/*
export interface Keyword {
    id: string;
    name: string;
    color: string;
    expression: string;
    position: number;
    notification: boolean;
}
    */

export const DraggableContainerKeyword: FC<KeywordDraggableProps> = ({
  currentKeywordDraggable,
  category,
  onEditCategory,
  onClickRemoveKeyword,
}) => {
  // const [editCategoryName, setEditCategoryName] = useState(false);
  return (
    <div
      className="flex-col items-center justify-between rounded-lg mt-1 py-2 px-1"
      style={{
        backgroundColor: '#FAFAFA',
        minHeight: '120px',
      }}
    >
      <div className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-row items-center mt-7">
          <div className="flex">
            <Text text="Keyword" />
          </div>
          {/*  
          {editCategoryName ? (
            <div>
              <div className="flex">
                <Text text="Nome categoria" />
                <span className="text-xs align-super">*</span>:
              </div>
              <div className="inline-flex w-11/12">
                <Input
                  placeholder="Attribuisci un nome alla categoria"
                  value={category}
                  onChange={(e) => onEditCategory(e.target.value)}
                  className="mr-2 w-auto"
                  required
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-row">
              <Text
                text={category}
                skipTranslation={true}
                className="font-semibold"
                style={{
                  fontSize: '1.125rem',
                  lineHeight: '1.75rem',
                }}
              />
              <SvgIcon
                svg={<Edit className="translate-y-1 fill-black h-5 w-5 ml-1" />}
                pointer={true}
                onClick={() => setEditCategoryName(true)}
              />
            </div>
          )}
*/}
        </div>
      </div>
      <div className="px-4 py-2 bg-[#EFEFEF] rounded">
        {currentKeywordDraggable.length > 0 ? (
          currentKeywordDraggable.map((keyword, i) => (
            <DraggableKeywordList
              key={keyword.id}
              index={i}
              id={keyword.id}
              title={keyword.name}
              onClickRemove={(id: string) => onClickRemoveKeyword(id)}
              showRemove={true}
            />
          ))
        ) : (
          <div className="flex flex-col justify-center items-center h-full">
            <SvgIcon svg={<DropHere className="h-6 w-6" />} />
            <Text text="Trascina qui le keywords" />
          </div>
        )}
      </div>
    </div>
  );
};
