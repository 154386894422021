import { FC, useState } from 'react';
import { Text } from '../Text';
import { DraggableTagList } from './DraggableTagList';
import { SvgIcon } from '../SvgIcon';
import { DropHere, Edit } from '../../svg';
import { Input } from '../Input';
import { Tag } from '@nwa/graphql';

export interface TagDraggableProps {
  currentTagDraggable: Tag[];
  category: string;
  onEditCategory: (name: string) => void;
  onClickRemoveTag: (id: number) => void;
}

export const DraggableContainerTag: FC<TagDraggableProps> = ({
  currentTagDraggable,
  category,
  onEditCategory,
  onClickRemoveTag,
}) => {
  const [editCategoryName, setEditCategoryName] = useState(false);
  return (
    <div
      className="flex-col items-center justify-between rounded-lg mt-1 py-2 px-1"
      style={{
        backgroundColor: '#FAFAFA',
        minHeight: '120px',
      }}
    >
      <div className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-row items-center">
          {editCategoryName ? (
            <div>
              <div className="flex">
                <Text text="Keywords" />
              </div>
              <div className="flex">
                <Text text="Nome categoria" />
                <span className="text-xs align-super">*</span>:
              </div>
              <div className="inline-flex w-11/12">
                <Input
                  placeholder="Attribuisci un nome alla categoria"
                  value={category}
                  onChange={(e) => onEditCategory(e.target.value)}
                  className="mr-2 w-auto"
                  required
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-row">
              <Text
                text={category}
                skipTranslation={true}
                className="font-semibold"
                style={{
                  fontSize: '1.125rem',
                  lineHeight: '1.75rem',
                }}
              />
              <SvgIcon
                svg={<Edit className="translate-y-1 fill-black h-5 w-5 ml-1" />}
                pointer={true}
                onClick={() => setEditCategoryName(true)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <Text text="Topic" />
      </div>
      <div className="px-4 py-2 bg-[#EFEFEF] rounded">
        {currentTagDraggable.length > 0 ? (
          currentTagDraggable.map((tag, i) => (
            <DraggableTagList
              key={tag.id}
              index={i}
              id={tag.id}
              title={tag.description}
              onClickRemove={(id: number) => onClickRemoveTag(id)}
              showRemove={true}
            />
          ))
        ) : (
          <div className="flex flex-col justify-center items-center h-full">
            <SvgIcon svg={<DropHere className="h-6 w-6" />} />
            <Text text="Trascina qui i topic" />
          </div>
        )}
      </div>
    </div>
  );
};
