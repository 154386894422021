import { FC, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';
import { NavigationBackComponent } from '../NavigationBack';
import { Divider, CheckboxSelected, CheckboxUnselected } from '../../svg';
import { NumberInput } from './NumberInput';
import { PercentInput } from './PercentInput';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { useInitialFillCategories } from '../hooks/useInitialFillTheFilterChoices';
import { SingleDateFilter } from './SingleDateFilter';
import { ProvidersAiFilter } from './ProvidersAiFilter';
import { HoursFilter } from './HoursFilter';
import { ContentType, Provider } from '@nwa/graphql';
import { ContentAiTypesFilter } from './ContentAiTypesFilter';
import { useMutationHook } from '.././hooks/useMutationHook';
import { NEW_PRESS_AI } from '../../graphql/news/mutation';
import { PressType } from '@nwa/graphql';
import { addLoading, removeLoading } from '../../redux/utils/actions';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CategoryDropdown from '../CategoryDropdown';

interface NewsPressAiProps {
  isHighlight: boolean;
}

export const NewsPressAi: FC<NewsPressAiProps> = ({ isHighlight }) => {
  const { t } = useTranslation();
  const [dateFilter, setDateFilter] = useState<Date | undefined>(new Date());
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [providersFilter, setProvidersFilter] = useState<Provider[]>([]);
  const [contentTypesFilter, setContentTypesFilter] = useState<ContentType[]>(
    []
  );
  const maxNews = 40;
  // default time
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [timeRange, setTimeRange] = useState({
    startTime: '',
    endTime: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log('timeRange', timeRange);
  // console.log('startTime', startTime);
  // console.log('endTime', endTime);
  // console.log('dateFilter', dateFilter);
  // console.log('typeof dateFilter', typeof dateFilter);

  // time error
  const [timeError, setTimeError] = useState('');

  useEffect(() => {
    setTimeRange({ startTime, endTime });
    // set data filter
    // setDateFilter(new Date());
  }, [startTime, endTime]);

  // check time
  useEffect(() => {
    if (startTime !== '' && endTime !== '') {
      if (startTime > endTime) {
        setTimeError(
          "L'orario di inizio deve essere inferiore a quello di fine"
        );
      } else {
        setTimeError('');
      }
    }
  }, [startTime, endTime]);

  const dateRange = useMemo(() => {
    console.log('timeRange inside useMemo', timeRange);
    // console.log('dateFilter inside useMemo', dateFilter);
    if (dateFilter) {
      // console.log('dateFilter', dateFilter);
      let date = null;
      if (typeof dateFilter === 'string') {
        date = Number.parseInt(dateFilter);
      } else {
        date = dateFilter;
      }
      let start = new Date(date);
      let end = new Date(date);
      console.log('start', start);
      console.log('end', end);
      if (timeRange.startTime && timeRange.endTime) {
        start.setHours(
          parseInt(timeRange.startTime.split(':')[0]),
          parseInt(timeRange.startTime.split(':')[1]),
          0,
          0
        );
        end.setHours(
          parseInt(timeRange.endTime.split(':')[0]),
          parseInt(timeRange.endTime.split(':')[1]),
          59,
          999
        );
      } else {
        console.log('start', start);
        console.log('end', end);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
      }
      console.log('start', start);
      console.log('end', end);
      return {
        from: start.getTime(),
        to: end.getTime(),
      };
    }
    let start = new Date();
    let end = new Date();
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
    return {
      from: start.getTime(),
      to: end.getTime(),
    };
  }, [dateFilter, timeRange]);

  // print  date range
  useEffect(() => {
    console.log('dateRange', dateRange);
  }, [dateRange]);

  // Total news variables
  const [totalNewsInputError, setTotalNewsInputError] = useState('');
  const [totalNewsValue, setTotalNewsValue] = useState('');
  // Percent variables
  const [percentInputError, setPercentInputError] = useState(''); // Stato per eventuali errori di input
  const [percentValues, setPercentValues] = useState<{ [key: string]: string }>(
    {}
  );

  // Text area Details
  const [detailsText, setDetailsText] = useState('');

  const { categories } = useSelector(filterChoicesSelector);

  // importo la funzione che mi permette di popolare le categorie
  useInitialFillCategories();
  console.log('categories', categories);

  const { refetch } = useInitialFillCategories(); // Chiamata dell'hook

  useEffect(() => {
    if (categories.length === 0) {
      refetch(); // Popola le categorie se l'array è vuoto
    }
  }, [categories.length, refetch]);

  useEffect(() => {
    let catB: { [key: string]: boolean } = {};
    let catV: { [key: string]: string } = {};
    categories.forEach((category) => {
      catB[category.id] = false;
      catV[category.id] = '1';
    });
    setSelected(catB);
    setPercentValues(catV);
  }, [categories]);

  // Funzione per gestire il cambio di valore di PercentInput per categoria
  const handlePercentValueChange = (newValue: string, categoryId: string) => {
    setPercentValues((prevValues) => ({
      ...prevValues,
      [categoryId]: newValue,
    }));
  };

  const percentTotalInputError = useMemo(() => {
    // Funzione per gestire l'input non valido di PercentInput per categoria
    const percentTotal = Object.keys(percentValues).reduce(
      (acc: number, key) =>
        acc + (selected[key] ? Number(percentValues[key]) : 0),
      0
    );
    if (percentTotal !== 100) {
      return 'La somma delle percentuali deve essere 100.';
    }
    return '';
  }, [percentValues, selected]);

  // funzione per visualizzare all'utente la percentuale residua
  const percentualeResidua = useMemo(() => {
    const totalePercentuale = Object.keys(percentValues).reduce(
      (acc, key) => acc + (selected[key] ? Number(percentValues[key]) : 0),
      0
    );
    return 100 - totalePercentuale;
  }, [percentValues, selected]);

  // handle details textarea
  const handleDetailsChange = (event: any) => {
    let value = event.target.value;
    setDetailsText(value);

    // Se il testo contiene '@', controlla per una corrispondenza di categoria
    if (value.includes('@')) {
      // Ottiene l'ultimo segmento di testo dopo '@'
      const parts = value.split('@');
      const search = parts[parts.length - 1].toLowerCase();

      // Qui presumo che tu abbia un modo per determinare se "search" corrisponde a una categoria
      // For example, filtrando un array di categorie possibili. Sostituisci "categories" con il tuo array di categorie.
      const matches = categories.filter((categoria) =>
        categoria.name.toLowerCase().startsWith(search)
      );

      // Mostra il dropdown solo se ci sono corrispondenze
      if (matches.length > 0) {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    } else {
      setShowDropdown(false);
    }
  };

  const [newAiPressMutation, { data }] = useMutationHook({
    queryGql: NEW_PRESS_AI,
    successMessage: 'Il salvataggio è andato a buon fine',
    errorMessage: 'Si è verificato un errore', //errorMessage: 'Non sono presenti notizie per i filtri selezionati', TODO: customize error message ["status": 502,]
  });

  const handleSendPressAI = () => {
    // Prepara l'oggetto dati per la mutation
    const pressAIInput = {
      detail: {
        title: isHighlight ? t('Rassegna') : t('Raccolta'),
        indexEnabled: localStorage.getItem('PRESS_SHOW_INDEX') === '1',
        imagesEnabled: localStorage.getItem('PRESS_SHOW_IMAGES') === '1',
        previewVideoEnabled: false,
        backgroundEnabled:
          localStorage.getItem('PRESS_SHOW_BACKGROUND') === '1',
        news: [],
      },
      type: isHighlight ? PressType.HIGHLIGHT : PressType.RELEASE,
      maxNumOfNews: parseInt(totalNewsValue),
      categories: categories
        .filter((cat) => selected[cat.id])
        .map((cat) => ({
          id: cat.id,
          percentage: parseInt(percentValues[cat.id]),
        })),
      dateRange: dateRange,
      providers:
        providersFilter.length > 0
          ? providersFilter
          : [
              Provider.ADNKRONOS,
              Provider.AGI,
              Provider.ANSA,
              Provider.ASKANEWS,
              Provider.DOW_JONES,
              Provider.MF_DOW_JONES,
              Provider.DIRE,
              Provider.RADIOCOR,
              Provider.REUTERS,
              Provider.TELEBORSA,
            ], // Provider []
      contentTypes: contentTypesFilter, // ContentType []
      description: detailsText ? detailsText : null,
      conversationId: '123456',
    };

    // Call graphQl newsAi mutation
    dispatch(addLoading());
    newAiPressMutation({
      variables: {
        input: pressAIInput,
      },
    }).finally(() => dispatch(removeLoading()));
  };

  useEffect(() => {
    if (data && data[firstLowerCaseGqlDefinition(NEW_PRESS_AI)]) {
      navigate(
        `/newswire/${isHighlight ? 'highlights' : 'newspress'}/${
          data[firstLowerCaseGqlDefinition(NEW_PRESS_AI)]
        }`
      );
    }
  }, [data, isHighlight, navigate]);

  const isButtonEnabled = useMemo(
    () =>
      percentTotalInputError === '' &&
      totalNewsInputError === '' &&
      Object.keys(selected).some((key) => selected[key] === true) &&
      timeError === '',
    [percentTotalInputError, totalNewsInputError, selected, timeError]
  );

  // Preparazione dell'array di stringhe delle categorie selezionate

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  // set selected categories
  useEffect(() => {
    // Filtra le categorie selezionate e crea un array di nomi categorie
    setSelectedCategories(
      categories.filter((cat) => selected[cat.id]).map((cat) => cat.name)
    );
  }, [categories, selected]);

  const selectCategory = (categoria: string) => {
    // Trova l'ultima posizione di '@' nel messaggio
    const lastIndex = detailsText.lastIndexOf('@');

    // Se '@' è presente, inserisce la categoria selezionata dopo l'ultimo '@'
    // Altrimenti, aggiunge '@' seguito dalla categoria alla fine del messaggio
    const updatedMessage =
      lastIndex !== -1
        ? `${detailsText.substring(0, lastIndex + 1)}"${categoria}"`
        : `${detailsText}@"${categoria}"`;

    setDetailsText(updatedMessage);

    setShowDropdown(false);
  };

  return (
    <div style={{ backgroundColor: '#FAFAFA' }} className="h-full">
      <div
        style={{ backgroundColor: '#FAFAFA' }}
        className="flex flex-col h-screen w-full"
      >
        <div
          className="h-12 w-full pl-3 py-2 mt-1 flex items-center justify-between bg-white"
          style={{ boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="flex items-center">
            <NavigationBackComponent />
            <SvgIcon svg={<Divider className="m-1" />} />

            <Text
              text={
                isHighlight ? t('Filtri Rassegna AI') : t('Filtri Raccolta AI')
              }
              className="ml-3 font-semibold"
            />
          </div>
        </div>
        <div className="flex h-full w-full py-10 px-20 rounded-xl flex-col scrollbar overflow-y-auto mt-0.5 mb-8">
          <Text text="Numero totale notizie" className="flex mb-5 font-bold" />

          <div className="flex flex-col">
            <div className="flex">
              <div className="flex items-center my-0 mx-1">
                <Text
                  text={`Quante notizie totali desideri nella tua ${
                    isHighlight ? 'rassegna' : 'raccolta'
                  }`}
                  style={{
                    fontSize: '1.125rem',
                    lineHeight: '1.75rem',
                  }}
                />
              </div>
              <div className="flex items-center my-0 mx-1">
                <NumberInput
                  min={1}
                  max={maxNews}
                  onInvalidInput={setTotalNewsInputError}
                  onValueChange={setTotalNewsValue}
                />{' '}
              </div>
            </div>
            {totalNewsInputError && (
              <div className="text-red-500 ml-10">{totalNewsInputError}</div>
            )}
          </div>

          <Text text="Categorie" className="flex mt-12 mb-5 font-bold" />
          <div className="text-blue-500 mb-3">
            {percentualeResidua < 100 && (
              <>Percentuale residua da assegnare: {percentualeResidua}%</>
            )}
          </div>
          <div className="flex flex-wrap items-center">
            {/* Controlla se ci sono categorie */}
            {categories.length > 0 ? (
              categories.map((category) => (
                <div key={category.id} className="flex mb-10 mr-20">
                  <div className="flex items-center my-0 mx-1">
                    <SvgIcon
                      svg={
                        selected[category.id] ? (
                          <CheckboxSelected />
                        ) : (
                          <CheckboxUnselected />
                        )
                      }
                      className="w-6 h-6"
                      pointer={true}
                      onClick={() =>
                        setSelected((prevSelected) => ({
                          ...prevSelected,
                          [category.id]: !prevSelected[category.id],
                        }))
                      }
                    />
                    <Text text={category.name} className="mx-5" />
                    <div className="">
                      <PercentInput
                        onInvalidInput={setPercentInputError}
                        onValueChange={(newValue) =>
                          handlePercentValueChange(newValue, category.id)
                        }
                        // boolean selected
                        selected={selected[category.id]} // Passa il valore booleano al percent input
                      />
                    </div>
                    <Text text="%" className="mx-5" />
                  </div>
                </div>
              ))
            ) : (
              // Loader - Spinner
              <div className="flex items-center justify-center m-10">
                <div className="w-24 h-24 border-l-4 border-[#eb7e7a] rounded-full animate-twSpin animate-infinite"></div>
              </div>
            )}
          </div>

          {percentInputError && (
            <div className="text-red-500 ml-10">{percentInputError}</div>
          )}

          {percentTotalInputError && (
            <div className="text-red-500 ml-10">{percentTotalInputError}</div>
          )}
          <div className="flex flex-wrap mt-12 mb-5 font-bold items-center">
            <div className="flex flex-col mb-10 mr-40 ">
              <Text text="Giorno" className="font-bold mb-4" />

              <SingleDateFilter
                setDateFilter={setDateFilter}
                dateFilter={dateFilter}
              />
            </div>
            <div className="flex flex-col mb-10 mr-40">
              <Text text="Orario" className="font-bold" />
              <HoursFilter
                setStartTime={setStartTime}
                setEndTime={setEndTime}
              />
              {timeError && <div className="text-red-500">{timeError}</div>}
            </div>
            <div className="flex flex-col mb-10 mr-40 ">
              <Text text="Fonti" className="font-bold mb-4" />

              <ProvidersAiFilter setProvidersFilter={setProvidersFilter} />
            </div>
            <div className="flex flex-col mb-10 mr-40">
              <Text text="Formati" className="font-bold mb-4" />
              <ContentAiTypesFilter
                setContentTypesFilter={setContentTypesFilter}
              />
            </div>
          </div>

          <div className="flex flex-col mb-10 mr-40 mt-12">
            <Text text="Dettagli" className="font-bold mb-4" />
            {showDropdown && (
              <div className="bg-white border border-gray-300 rounded-md">
                <CategoryDropdown
                  categories={selectedCategories}
                  onSelect={selectCategory}
                />
              </div>
            )}
            <textarea
              className="border-gray-300 rounded w-full h-24 border p-2 resize-none"
              placeholder="Aggiungi una descrizione sul sentiment o i topic desiderati"
              value={detailsText} // Imposta il valore della textarea allo stato attuale
              onChange={handleDetailsChange} // Aggiorna lo stato ogni volta che il testo cambia
            />
          </div>

          <div className="flex justify-end mb-5 items-center">
            <button
              disabled={!isButtonEnabled}
              onClick={handleSendPressAI}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 disabled:bg-blue-300"
            >
              <Text
                text={`${isHighlight ? 'Crea Rassegna' : 'Crea Raccolta'}`}
                className="font-bold mb-4"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
