import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { GenericMultiSelect } from '../GenericMultiSelect';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { ContentType } from '@nwa/graphql';

interface ContentAiTypesFilterProps {
  setContentTypesFilter: (contentTypesFilter: ContentType[]) => void;
}

export const ContentAiTypesFilter: FC<ContentAiTypesFilterProps> = ({
  setContentTypesFilter,
}) => {
  const { contentTypes } = useSelector(filterChoicesSelector);
  const newsFilter = useSelector(newsFilterSelector);
  const [defaultValueIds, setDefaultValueIds] = useState<string[]>([]);
  useEffect(() => {
    let tmp: string[] = [];
    if (newsFilter.contentTypes && newsFilter.contentTypes.length > 0) {
      contentTypes.forEach((contentType) => {
        newsFilter.contentTypes?.forEach((contentTypeFilter) => {
          if (contentType.value === contentTypeFilter) {
            tmp.push(contentType.value);
          }
        });
      });
    }
    setDefaultValueIds(tmp);
  }, [newsFilter.contentTypes, contentTypes]);
  return (
    <GenericMultiSelect
      id="ContentTypes"
      showSelectedOption={true}
      onChange={(newValue) => {
        const contentTypesMap = newValue.map(
          (contentType) => contentType.value as ContentType
        );
        setContentTypesFilter(contentTypesMap);
      }}
      options={contentTypes}
      placeholder={'Tutti i formati'}
      defaultValueIds={defaultValueIds}
    />
  );
};
