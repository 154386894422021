import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GenericMultiSelect } from '../GenericMultiSelect';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { Provider } from '@nwa/graphql';

interface ProvidersAiFilterProps {
  setProvidersFilter: (providersFilter: Provider[]) => void;
}

export const ProvidersAiFilter: FC<ProvidersAiFilterProps> = ({
  setProvidersFilter,
}) => {
  const { providers } = useSelector(filterChoicesSelector);
  const newsFilter = useSelector(newsFilterSelector);
  const [defaultValueIds, setDefaultValueIds] = useState<string[]>([]);

  useEffect(() => {
    let tmp: string[] = [];
    if (newsFilter.providers && newsFilter.providers.length > 0) {
      providers.forEach((provider) => {
        newsFilter.providers?.forEach((providerFilter) => {
          if (provider.value === providerFilter) {
            tmp.push(provider.value);
          }
        });
      });
    }
    setDefaultValueIds(tmp);
  }, [newsFilter.providers, providers]);
  return (
    <GenericMultiSelect
      id="Providers"
      showSelectedOption={true}
      onChange={(newValue) => {
        const newProvidersMap = newValue.map(
          (provider) => provider.value as Provider
        );
        setProvidersFilter(newProvidersMap);
      }}
      options={providers}
      placeholder={'Tutte le fonti'}
      defaultValueIds={defaultValueIds}
    />
  );
};
