import React, { FC } from 'react';
import { Text } from '../Text';
interface HoursFilterProps {
  setStartTime: (startTime: string) => void;
  setEndTime: (endTime: string) => void;
}

export const HoursFilter: FC<HoursFilterProps> = ({
  setStartTime,
  setEndTime,
}) => {
  // From time
  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartTime(event.target.value);
  };

  // To Time
  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(event.target.value);
  };

  return (
    <div className="relative w-52 h-16">
      <Text
        text={'Da'}
        className="absolute top-1 left-1 text-black text-xs font-light"
      />

      <input
        type="time"
        className="absolute w-26 h-10 pl-2 pr-3.5 pt-2 pb-2 left-0 top-6 border border-gray-300 rounded"
        // value={setStartTime}
        onChange={handleStartTimeChange}
      />

      <Text
        text={'A'}
        className="absolute top-0 right-1 text-black text-xs font-light"
      />

      <input
        type="time"
        className="absolute w-26 h-10 pl-2 pr-3.5 pt-2 pb-2 right-0 top-6 border border-gray-300 rounded"
        // value={endTime}
        onChange={handleEndTimeChange}
      />
    </div>
  );
};
