import { FC, useState } from 'react';
import { SerpAPIGeo } from '@nwa/graphql';
import { GenericSelect } from '../GenericSelect';

export interface TrendGeoFilterProps {
  geo: SerpAPIGeo;
  setGeo: (geo: SerpAPIGeo) => void;
}

export const TrendGeoFilter: FC<TrendGeoFilterProps> = ({ geo, setGeo }) => {
  const [currentValue, setCurrentValue] = useState<TrendGeoObject>(
    geos.find((g) => g.value === geo) || geos[0]
  );
  return (
    <GenericSelect
      id="trendGeoFilter"
      onChange={(newValue) => {
        setGeo(newValue?.value as SerpAPIGeo);
        setCurrentValue(geos.find((g) => g.value === newValue?.value)!);
      }}
      options={geos}
      placeholder={''}
      value={currentValue}
      showSelectedOption={true}
      showSelectionCircle={false}
      showSelectedOptionIcon={false}
      showSelectedOptionLabel={true}
      disableBorder={true}
      menuRight={true}
    />
  );
};

const geoLabels: any = {
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  BE: 'Belgium',
  BR: 'Brazil',
  CA: 'Canada',
  CL: 'Chile',
  CO: 'Colombia',
  CZ: 'Czechia',
  DK: 'Denmark',
  EG: 'Egypt',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IN: 'India',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JP: 'Japan',
  KE: 'Kenya',
  MY: 'Malaysia',
  MX: 'Mexico',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NG: 'Nigeria',
  NO: 'Norway',
  PE: 'Peru',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  RU: 'Russia',
  SA: 'Saudi Arabia',
  SG: 'Singapore',
  ZA: 'South Africa',
  KR: 'South Korea',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  TW: 'Taiwan',
  TH: 'Thailand',
  TR: 'Türkiye',
  UA: 'Ukraine',
  GB: 'United Kingdom',
  US: 'United States',
  VN: 'Vietnam',
};

const geos: TrendGeoObject[] = [
  {
    label: geoLabels[SerpAPIGeo.IT],
    value: SerpAPIGeo.IT,
  },
  ...Object.keys(geoLabels)
    .filter((g) => g !== SerpAPIGeo.IT)
    .map((g) => ({
      label: geoLabels[g] as string,
      value: g as SerpAPIGeo,
    })),
];

interface TrendGeoObject {
  value: string;
  label: string;
}
