import { ChartTrendTopic } from '@nwa/graphql';
import { FC, useMemo } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

export interface TrendChartProps {
  chart?: ChartTrendTopic;
  width: number;
  height: number;
  big: boolean;
}

export const TrendChart: FC<TrendChartProps> = ({
  chart,
  height,
  big,
  width,
}) => {
  function CustomTooltip({
    payload,
    label,
    active,
  }: TooltipProps<ValueType, NameType>) {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload?.[0].value}%`}</p>
        </div>
      );
    }

    return null;
  }
  const data = useMemo(
    () =>
      !chart
        ? []
        : big
        ? chart.timelineData
        : chart.timelineData.slice(
            chart.timelineData.length - 10,
            chart.timelineData.length
          ),
    [chart, big]
  );
  return chart ? (
    <LineChart width={width} height={height} data={data}>
      <Line
        type="monotone"
        dataKey="value.extractedValue"
        stroke="#4285F4"
        dot={big}
      />
      {big && (
        <>
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
        </>
      )}
    </LineChart>
  ) : (
    <div className={`flex items-center justify-center ${big ? 'p-4' : 'p-1'}`}>
      <div
        className={`${
          big ? 'w-32 h-32' : 'w-10 h-10'
        } border-l-4 border-[#4285F4] rounded-full animate-twSpin animate-infinite`}
      ></div>
    </div>
  );
};
