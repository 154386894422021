import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select, {
  SingleValue,
  ActionMeta,
  components,
  StylesConfig,
  DropdownIndicatorProps,
  GroupBase,
  MenuPlacement,
} from 'react-select';
import { Text } from './Text';
import { DownArrow, RadioSelected, RadioUnselected } from '../svg';
import { SvgIcon } from './SvgIcon';
import { useSelector, useDispatch } from 'react-redux';
import {
  setClickAfterForceUnfocus,
  setCurrentFilterFocused,
} from '../redux/utils/actions';
import { utilsSelector } from '../redux/utils/selectors';
import { workingAreasSelector } from '../redux/workingAreas/selectors';

interface GenericSelectProps {
  id: string;
  options: GenericSelectPropsOptions[];
  onChange?: (
    newValue: SingleValue<GenericSelectPropsOptions>,
    actionMeta: ActionMeta<GenericSelectPropsOptions>
  ) => void;
  defaultValueIndex?: number;
  showSelectionCircle: boolean;
  actions?: GenericSelectPropsActions[];
  showSelectedOption: boolean;
  label?: string;
  placeholder?: string;
  noCustomValueContainer?: boolean;
  CustomControl?: FC;
  value?: SingleValue<GenericSelectPropsOptions> | undefined;
  showSelectedOptionLabel?: boolean;
  showSelectedOptionIcon?: boolean;
  isDisabled?: boolean;
  disableBorder?: boolean;
  menuRight?: boolean;
  showButtonControl?: boolean;
  onButtonClick?: () => void;
  buttonControlLabel?: string;
  onMenuOpen?: () => void;
  menuPlacement?: MenuPlacement;
}

interface GenericSelectPropsActions {
  label: string;
  value: string;
  count?: number;
  Icon?: any;
  onClick?: () => void;
  isDisabled?: boolean;
}

interface GenericSelectPropsOptions extends GenericSelectPropsActions {
  to?: string;
  skipTranslation?: boolean;
}

interface FormatOptionLabelProps extends GenericSelectPropsOptions {
  currentValue: SingleValue<GenericSelectPropsOptions>;
  showSelectionCircle: boolean;
}

const FormatOptionLabel: FC<FormatOptionLabelProps> = (props) => {
  return (
    <>
      {props.to ? (
        <Link
          to={props.to}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            cursor: props.isDisabled ? 'default' : 'pointer',
          }}
        >
          <FormatOptionLabelInner {...props} />
        </Link>
      ) : (
        <FormatOptionLabelInner {...props} />
      )}
    </>
  );
};

const FormatOptionLabelInner: FC<FormatOptionLabelProps> = ({
  value,
  label,
  count,
  Icon,
  currentValue,
  showSelectionCircle,
  isDisabled,
  onClick,
  skipTranslation,
}) => {
  return (
    <div
      className="flex items-center"
      onClick={isDisabled ? () => {} : onClick}
      style={{
        cursor: isDisabled ? 'default' : 'pointer',
      }}
    >
      {showSelectionCircle && (
        <SvgIcon
          svg={
            currentValue?.value === value ? (
              <RadioSelected />
            ) : (
              <RadioUnselected />
            )
          }
          className="w-5 h-5"
        />
      )}
      {Icon && (
        <SvgIcon
          svg={Icon}
          style={{
            fill: isDisabled ? '#ccc' : 'black',
          }}
          className="h-5 w-5"
        />
      )}
      <div
        style={{
          cursor: isDisabled ? 'default' : 'pointer',
          color: isDisabled ? '#ccc' : 'black',
          marginLeft: (showSelectionCircle || Icon) && label ? '10px' : '0px',
        }}
      >
        <div
          style={{
            textDecoration: 'none',
            color: 'inherit',
            cursor: isDisabled ? 'default' : 'pointer',
          }}
        >
          <Text
            text={label}
            style={{
              color: isDisabled ? '#ccc' : 'black',
            }}
            skipTranslation={skipTranslation}
          />
        </div>
      </div>
      {typeof count !== 'undefined' && (
        <div className="text-white bg-red-500 h-3 w-3 ml-1 leading-3 text-center rounded">
          <Text
            text={`${count}`}
            skipTranslation={true}
            style={{
              fontSize: '0.75rem',
              lineHeight: '1rem',
            }}
          />
        </div>
      )}
    </div>
  );
};

const SingleValueComponent = () => {
  return <></>;
};

export const GenericSelect: FC<GenericSelectProps> = ({
  id,
  onChange,
  options,
  defaultValueIndex,
  showSelectionCircle,
  actions,
  showSelectedOption,
  label,
  placeholder,
  noCustomValueContainer,
  CustomControl,
  value,
  showSelectedOptionLabel,
  showSelectedOptionIcon,
  isDisabled,
  disableBorder,
  menuRight,
  showButtonControl,
  onButtonClick,
  buttonControlLabel,
  onMenuOpen,
  menuPlacement,
}) => {
  const [currentValue, setCurrentValue] = useState<
    SingleValue<GenericSelectPropsOptions>
  >(
    typeof defaultValueIndex !== 'undefined' ? options[defaultValueIndex] : null
  );
  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  const noBorderStyles: StylesConfig<GenericSelectPropsOptions, false> = {
    menuList: (styles) => ({
      ...styles,
      height: '100%',
      overflowY: 'auto',
      scrollBehavior: 'smooth',

      '::-webkit-scrollbar': {
        width: '0.5rem',
        height: '0px',
        display: 'block',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(177, 180, 187, 0.5)',
        borderRadius: '5px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(175, 179, 185)',
        borderRadius: '14px',
        border: '3px solid rgba(177, 180, 187, 0.5)',
      },
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      translate:
        !showSelectedOptionLabel && showSelectedOptionIcon ? '-.125rem' : '',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isFocused
          ? '#FFE2E8'
          : isSelected
          ? 'transparent'
          : undefined,
        color: isDisabled ? '#ccc' : isSelected ? 'inherit' : 'inherit',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? 'inherit'
              : 'inherit'
            : undefined,
        },
      };
    },
    menu: (styles) => ({
      ...styles,
      width: 'max-content',
      minWidth: '100%',
      right: menuRight ? 0 : 'initial',
      zIndex: 270,
    }),
    indicatorSeparator: () => ({}),
    dropdownIndicator: (styles) => ({ ...styles, paddingLeft: '2px' }),
    placeholder: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : '#454343',
    }),
  };

  const borderStyles: StylesConfig<GenericSelectPropsOptions, false> = {
    menuList: (styles) => ({
      ...styles,
      height: '100%',
      overflowY: 'auto',
      scrollBehavior: 'smooth',

      '::-webkit-scrollbar': {
        width: '0.5rem',
        height: '0px',
        display: 'block',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(177, 180, 187, 0.5)',
        borderRadius: '5px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(175, 179, 185)',
        borderRadius: '14px',
        border: '3px solid rgba(177, 180, 187, 0.5)',
      },
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      border: '1px solid #cccccc',
      '&:active': {
        border: '1px solid #3B82F6 ',
      },
      '&:hover': {
        border: '1px solid #AAA8A8',
      },
      boxShadow:
        isFocused && !forceUnfocus && currentFilterFocused === id
          ? '0 0 0 1px #2684ff'
          : 'none',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isFocused
          ? '#FFE2E8'
          : isSelected
          ? 'transparent'
          : undefined,
        color: isDisabled ? '#ccc' : isSelected ? 'inherit' : 'inherit',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? 'inherit'
              : 'inherit'
            : undefined,
        },
      };
    },
    menu: (styles) => ({
      ...styles,
      width: 'max-content',
      minWidth: '100%',
      right: menuRight ? 0 : 'initial',
      zIndex: 270,
    }),
    dropdownIndicator: (styles) => ({ ...styles, paddingLeft: '2px' }),
    placeholder: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : '#454343',
    }),
    indicatorSeparator: () => ({}),
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const DropdownIndicatorComponent = (
    props: DropdownIndicatorProps<
      GenericSelectPropsOptions,
      false,
      GroupBase<GenericSelectPropsOptions>
    >
  ) => (
    <components.DropdownIndicator {...props}>
      <SvgIcon
        svg={<DownArrow />}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        style={{
          display: 'inline-block',
          fill: 'currentColor',
          lineHeight: '1',
          stroke: 'currentColor',
          strokeWidth: '0',
          transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      />
    </components.DropdownIndicator>
  );

  const ActionOption: FC<GenericSelectPropsActions> = ({
    value,
    label,
    count,
    Icon,
    onClick,
    isDisabled,
  }) => {
    return (
      <div
        style={{
          cursor: isDisabled ? 'default' : 'pointer',
          padding: '8px 12px',
          color: isDisabled ? '#ccc' : 'black',
        }}
        className="flex items-center"
      >
        {Icon && (
          <SvgIcon
            svg={Icon}
            style={{
              fill: isDisabled ? '#ccc' : 'black',
            }}
            className="h-5 w-5"
          />
        )}
        <div
          style={{
            marginLeft: Icon ? '10px' : '0px',
          }}
          onClick={
            isDisabled
              ? () => {}
              : () => {
                  onClick && onClick();
                  setMenuIsOpen(false);
                }
          }
        >
          <Text
            text={label}
            style={{
              color: isDisabled ? '#ccc' : 'black',
              fontStyle: 'italic',
            }}
          />
        </div>
        {typeof count !== 'undefined' && (
          <div className="text-white bg-red-500 h-3 w-3 ml-1 leading-3 text-center rounded">
            <Text
              text={`${count}`}
              skipTranslation={true}
              style={{
                fontSize: '0.75rem',
                lineHeight: '1rem',
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const MenuList = (
    props: any,
    actions: GenericSelectPropsActions[] | undefined
  ) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        {actions && (
          <>
            <hr />
            {actions.map((action) => (
              <ActionOption {...action} key={action.value} />
            ))}
          </>
        )}
      </components.MenuList>
    );
  };

  const ControlWithButton = ({ children, ...props }: any) => (
    <>
      <components.Control {...props}>
        <div className="flex cursor-pointer ml-1">
          <span
            onClick={(e) => {
              onButtonClick && onButtonClick();
              setMenuIsOpen(false);
              e.stopPropagation();
            }}
          >
            <Text
              text={buttonControlLabel || 'buttonControlLabel'}
              className={`${isDisabled ? 'text-gray-300' : 'text-blue-500'}`}
            />
          </span>
          &nbsp;
          <span className={`${isDisabled ? 'text-gray-300' : 'text-blue-500'}`}>
            |
          </span>
        </div>
        {children}
      </components.Control>
    </>
  );

  const ValueContainer = (
    { children, ...props }: any,
    {
      showSelectedOption,
      label,
      showSelectedOptionLabel,
      showSelectedOptionIcon,
    }: any
  ) => {
    var option = Object.assign({}, props.selectProps.value);
    option.showSelectionCircle = false;
    if (!showSelectedOptionIcon) option.Icon = null;
    if (!showSelectedOptionLabel) option.label = '';
    return (
      <>
        <div className="ml-1"></div>
        <span onClick={() => setMenuIsOpen(!menuIsOpen)}>
          {showSelectedOption && props.selectProps.value ? (
            <FormatOptionLabel {...option} {...props} />
          ) : (
            <div>
              <Text
                text={label}
                className="uppercase"
                style={{ color: props.isDisabled ? '#ccc' : 'inherit' }}
              />
            </div>
          )}
        </span>
        {showSelectedOptionLabel && <div className="ml-1"></div>}
        {children}
      </>
    );
  };

  const PlaceholderComponent = ({ children, ...props }: any) => (
    <>
      <components.Placeholder {...props}>
        <span onClick={() => setMenuIsOpen(!menuIsOpen)}>{children}</span>
      </components.Placeholder>
    </>
  );

  const { draftWorkingArea } = useSelector(workingAreasSelector);
  const { clickAfterForceUnfocus, currentFilterFocused } =
    useSelector(utilsSelector);
  const dispatch = useDispatch();
  const [forceUnfocus, setForceUnfocus] = useState(false);
  useEffect(() => {
    setForceUnfocus(true);
    dispatch(setClickAfterForceUnfocus(true));
    dispatch(setCurrentFilterFocused(undefined));
  }, [draftWorkingArea, dispatch]);
  useEffect(() => {
    if (!clickAfterForceUnfocus) {
      setForceUnfocus(false);
    }
  }, [clickAfterForceUnfocus]);

  return (
    <Select
      menuPlacement={menuPlacement || 'auto'}
      onMenuOpen={() => {
        onMenuOpen && onMenuOpen();
        setMenuIsOpen(true);
        dispatch(setClickAfterForceUnfocus(false));
        dispatch(setCurrentFilterFocused(id));
      }}
      options={options}
      components={{
        ValueContainer: (props) =>
          noCustomValueContainer
            ? components.ValueContainer(props)
            : ValueContainer(props, {
                showSelectedOption,
                label,
                showSelectedOptionLabel,
                showSelectedOptionIcon,
              }),
        MenuList: (props) => MenuList(props, actions),
        SingleValue: () => SingleValueComponent(),
        Control: showButtonControl
          ? ControlWithButton
          : CustomControl
          ? CustomControl
          : components.Control,
        DropdownIndicator: (props) => DropdownIndicatorComponent(props),
        Placeholder: (props) => PlaceholderComponent(props),
      }}
      onBlur={() => setMenuIsOpen(false)}
      formatOptionLabel={(data, formatOptionLabelMeta) =>
        FormatOptionLabel(
          { ...data, currentValue, showSelectionCircle },
          formatOptionLabelMeta
        )
      }
      onChange={(
        newValue: SingleValue<GenericSelectPropsOptions>,
        actionMeta: ActionMeta<GenericSelectPropsOptions>
      ) => {
        setCurrentValue(newValue);
        onChange && onChange(newValue, actionMeta);
        setMenuIsOpen(false);
      }}
      defaultValue={currentValue}
      isSearchable={false}
      placeholder={placeholder ? placeholder : ''}
      value={value}
      isDisabled={isDisabled}
      styles={disableBorder ? noBorderStyles : borderStyles}
      menuIsOpen={menuIsOpen}
    />
  );
};
