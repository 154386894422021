import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GenericMultiSelect } from '../GenericMultiSelect';
import { setSentimentFilter } from '../../redux/newsFilter/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { FilterBoxChildrenProps } from '../FilterBox';
import { setWorkingAreaFilter } from '../../redux/workingAreas/actions';
import { Sentiment } from '@nwa/graphql';

export const SentimentFilter: FC<FilterBoxChildrenProps> = ({ isDisabled }) => {
  const { sentiments } = useSelector(filterChoicesSelector);
  const dispatch = useDispatch();
  const newsFilter = useSelector(newsFilterSelector);
  const [defaultValueIds, setDefaultValueIds] = useState<string[]>([]);
  useEffect(() => {
    let tmp: string[] = [];
    if (newsFilter.sentiments && newsFilter.sentiments.length > 0) {
      sentiments.forEach((sentiment) => {
        newsFilter.sentiments?.forEach((sentimentFilter) => {
          if (sentiment.value === sentimentFilter) {
            tmp.push(sentiment.value);
          }
        });
      });
    }
    setDefaultValueIds(tmp);
  }, [newsFilter.sentiments, sentiments]);
  return (
    <GenericMultiSelect
      id="Sentiments"
      showSelectedOption={true}
      onChange={(newValue) => {
        const newsentimentsMap = newValue.map((sentiment) => sentiment.value);
        dispatch(setSentimentFilter(newsentimentsMap));
        dispatch(
          setWorkingAreaFilter({
            ...newsFilter,
            sentiments: newsentimentsMap as Sentiment[],
          })
        );
      }}
      options={sentiments}
      placeholder={'Tutti i sentiment'}
      defaultValueIds={defaultValueIds}
      isDisabled={isDisabled}
    />
  );
};
