import type { FC } from 'react';
import { Text } from '../../Text';
import { DivWithHtml } from '../../DivWithHtml';
import { providers } from '../../../utils/iconUtils';
import { Provider } from '@nwa/graphql';
import { SvgIcon } from '../../SvgIcon';

export interface OtherNewsItemListProps {
  id: string;
  title: string;
  onClickTitle: (id: string) => void;
  onClickAdd: (id: string) => void;
  provider: Provider;
}

export const OtherNewsItemList: FC<OtherNewsItemListProps> = ({
  id,
  title,
  onClickAdd,
  onClickTitle,
  provider,
}) => (
  <div className="flex w-full items-center justify-between rounded-lg mt-1 p-1 bg-white max-w-full min-w-0 border border-red-200">
    <div className="flex items-center bg-white w-9/12">
      <SvgIcon svg={providers[provider]} pointer={false} />
      <DivWithHtml
        className="flex ml-1 cursor-pointer"
        onClick={() => onClickTitle(id)}
        html={title}
      />
    </div>
    <div
      className="flex items-center justify-end w-3/12 pr-1"
      onClick={() => onClickAdd(id)}
    >
      <Text
        text="Aggiungi alla lista"
        style={{
          fontSize: '0.75rem/* 12px */',
          lineHeight: '1rem/* 16px */',
          textDecoration: 'underline',
        }}
        className="cursor-pointer"
      />
    </div>
  </div>
);
