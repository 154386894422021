import { useEffect, useState } from 'react';
import { NewsSummary, WindowType } from '@nwa/graphql';
import { ApolloError } from '@apollo/client';
import {
  COUNT_NEWS,
  LIST_BOOKMARKED_NEWS,
  SEARCH_NEWS,
} from '../../graphql/news/queries';
import { useSelector } from 'react-redux';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { adapterEnvironmentToProviders } from './utils';
import { newsSelectionSelector } from '../../redux/newsSelection/selectors';
import { useLazyQueryHook } from './useLazyQueryHook';

export const useGetNews = (
  id: string,
  limit: number,
  inPolling?: boolean
): {
  data: NewsSummary[];
  hasMore: boolean;
  count: number;
  error?: ApolloError;
  loading: boolean;
  fetchMore: () => Promise<any>;
} => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const { draftWorkingArea, dateRange } = useSelector(workingAreasSelector);
  const { forceRefresh } = useSelector(newsSelectionSelector);

  const selectedWindow = draftWorkingArea?.windows.filter((w) => w.id === id)[0]
    ?.value;

  const [
    searchNews,
    {
      data: dataSearchNews,
      error: errorData,
      loading: loadingData,
      startPolling: startPollingSearchNews,
      stopPolling: stopPollingSearchNews,
    },
  ] = useLazyQueryHook({ queryGql: SEARCH_NEWS });

  const [hasMore, setHasMore] = useState(false);

  const [countNews, { data: countSearchNews }] = useLazyQueryHook({
    queryGql: COUNT_NEWS,
  });

  const [
    listBookmarkedNews,
    {
      data: dataBookmarkedNews,
      error: errorBookmark,
      loading: loadingBookmark,
      stopPolling: stopPollingListBookmarkedNews,
      startPolling: startPollingListBookmarkedNews,
    },
  ] = useLazyQueryHook({ queryGql: LIST_BOOKMARKED_NEWS });

  useEffect(() => {
    if (
      selectedWindow?.type === WindowType.SEARCH &&
      selectedWindow.search &&
      inPolling
    ) {
      const filter = {
        ...adapterEnvironmentToProviders(selectedWindow.search),
        dateRange: dateRange.filter((dr) => dr.id === id)[0]?.date,
      };
      searchNews({
        variables: {
          filter,
          pagination: {
            offset: 0,
            limit: limit,
          },
        },
        fetchPolicy: 'network-only',
      });
      startPollingSearchNews(15000);
      if (
        !selectedWindow.search?.fullSearchText ||
        dateRange.filter((dr) => dr.id === id)[0]?.date
      )
        countNews({
          variables: {
            filter,
          },
          fetchPolicy: 'network-only',
        });
    } else {
      stopPollingSearchNews();
    }
    // eslint-disable-next-line
  }, [inPolling, selectedWindow, forceRefresh]);

  useEffect(() => {
    if (dataSearchNews) {
      setData(
        mergeAndSort(
          data,
          dataSearchNews[firstLowerCaseGqlDefinition(SEARCH_NEWS)]
        )
      );
      setHasMore(
        dataSearchNews[firstLowerCaseGqlDefinition(SEARCH_NEWS)].length ===
          limit
      );
      if (
        selectedWindow?.search?.fullSearchText &&
        !dateRange.filter((dr) => dr.id === id)[0]?.date
      ) {
        setCount(
          dataSearchNews[firstLowerCaseGqlDefinition(SEARCH_NEWS)].length || 0
        );
      }
    }
    // eslint-disable-next-line
  }, [dataSearchNews]);

  useEffect(() => {
    if (countSearchNews) {
      setCount(countSearchNews[firstLowerCaseGqlDefinition(COUNT_NEWS)]);
    }
    // eslint-disable-next-line
  }, [countSearchNews]);

  useEffect(() => {
    if (dataBookmarkedNews) {
      setData(
        dataBookmarkedNews[firstLowerCaseGqlDefinition(LIST_BOOKMARKED_NEWS)]
      );
    }
  }, [dataBookmarkedNews]);

  useEffect(() => {
    if (selectedWindow?.type === WindowType.SEARCH && selectedWindow.search) {
      setData([]);
      const filter = {
        ...adapterEnvironmentToProviders(selectedWindow.search),
        dateRange: dateRange.filter((dr) => dr.id === id)[0]?.date,
      };
      searchNews({
        variables: {
          filter,
          pagination: {
            offset: 0,
            limit: limit,
          },
        },
        fetchPolicy: 'network-only',
      });
      if (
        !selectedWindow.search?.fullSearchText ||
        dateRange.filter((dr) => dr.id === id)[0]?.date
      )
        countNews({
          variables: {
            filter,
          },
          fetchPolicy: 'network-only',
        });
    }

    // eslint-disable-next-line
  }, [selectedWindow, searchNews]);

  useEffect(() => {
    if (
      selectedWindow?.type === WindowType.BOOKMARK &&
      selectedWindow.color &&
      inPolling
    ) {
      startPollingListBookmarkedNews(15000);
    } else {
      stopPollingListBookmarkedNews();
    }
    // eslint-disable-next-line
  }, [inPolling, draftWorkingArea]);

  useEffect(() => {
    if (selectedWindow?.type === WindowType.BOOKMARK && selectedWindow.color) {
      listBookmarkedNews({
        variables: {
          color: selectedWindow.color,
        },
        fetchPolicy: 'network-only',
      });
    }
    // eslint-disable-next-line
  }, [draftWorkingArea, forceRefresh]);

  return {
    data: data,
    count,
    hasMore,
    error: errorData || errorBookmark,
    loading: loadingData || loadingBookmark,
    fetchMore: () => {
      const filter = {
        ...adapterEnvironmentToProviders(selectedWindow?.search),
        dateRange: dateRange.filter((dr) => dr.id === id)[0]?.date,
      };
      if (
        !selectedWindow?.search?.fullSearchText ||
        dateRange.filter((dr) => dr.id === id)[0]?.date
      )
        countNews({
          variables: {
            filter,
          },
          fetchPolicy: 'network-only',
        });
      return searchNews({
        variables: {
          filter,
          pagination: {
            offset: data.length || 0,
            limit,
          },
        },
        fetchPolicy: 'network-only',
      });
    },
  };
};

const mergeAndSort = (a: any, b: any) => {
  var reduced = a.filter(
    (aitem: any) => !b.find((bitem: any) => aitem.id === bitem.id)
  );
  return reduced.concat(b).sort((a: any, b: any) => b.date - a.date);
};
