import React, {
  FC,
  LegacyRef,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Element as SlateElement,
  Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, useSlate, withReact } from 'slate-react';
import { Text } from '../../Text';

import { PressNewsDetail } from '@nwa/graphql';
import { useTranslation } from 'react-i18next';
import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatBulletList,
  FormatColor,
  FormatItalic,
  FormatNumberList,
  FormatUnderlined,
} from '../../../svg';
import {
  CustomElement,
  CustomText,
  deserialize,
  LIST_TYPES,
  serialize,
  TEXT_ALIGN_TYPES,
  Element,
} from './utils';
import { SvgIcon } from '../../SvgIcon';

/*const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  //'mod+`': 'code',
};*/

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export interface NewsEditorProps {
  news: PressNewsDetail;
  onSave: (press: PressNewsDetail) => Promise<any>;
  onCancel: () => void;
  type?: NewsEditorType;
}

export enum NewsEditorType {
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
  CONTENT = 'CONTENT',
}

export const NewsEditor: FC<NewsEditorProps> = ({
  news,
  onSave,
  onCancel,
  type,
}) => {
  const renderElement = useCallback(
    (
      props: JSX.IntrinsicAttributes & {
        attributes: any;
        children: any;
        element: any;
      }
    ) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (
      props: JSX.IntrinsicAttributes & {
        attributes: any;
        children: any;
        leaf: any;
      }
    ) => <Leaf {...props} />,
    []
  );

  const withHtml = useCallback((editor: BaseEditor & ReactEditor) => {
    const { insertData, isInline, isVoid } = editor;

    editor.isInline = (element) => {
      return element.type === 'link' ? true : isInline(element);
    };

    editor.isVoid = (element) => {
      return element.type === 'image' ? true : isVoid(element);
    };

    editor.insertData = (data) => {
      if (data.getData('application/x-slate-fragment')) {
        insertData(data);
        return;
      }
      let isHtml = true;
      let html = data.getData('text/html');
      if (!html) {
        html = data.getData('text/plain');
        isHtml = false;
      }

      if (html) {
        try {
          const parsed = new DOMParser().parseFromString(html, 'text/html');
          const fragment = deserialize(parsed.body, {}, false);
          Transforms.insertFragment(editor, fragment);
          return;
        } catch (e) {
          console.error(e);
          if (isHtml) return;
        }
      }

      insertData(data);
    };

    return editor;
  }, []);
  const editor = useMemo(
    () => withHtml(withHistory(withReact(createEditor()))),
    [withHtml]
  );
  // Define a serializing function that takes a value and returns a string.

  const initialValue: Descendant[] = useMemo(
    () => {
      let editContent = '';
      switch (type) {
        case NewsEditorType.CONTENT:
          editContent = news.enrichedContent;
          break;
        case NewsEditorType.TITLE:
          editContent = news.title;
          break;
        case NewsEditorType.SUBTITLE:
          editContent = news.headline || '<em></em>';
          break;

        default:
          break;
      }

      return deserialize(
        new DOMParser().parseFromString(editContent, 'text/html').body,
        {},
        false
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [news.enrichedContent]
  );

  //const [content, setContent] = useState<Descendant[]>([]);

  const onClickSave = () => {
    /*console.log(content);
    console.log(serialize(editor));*/
    switch (type) {
      case NewsEditorType.CONTENT:
        onSave({ ...news, enrichedContent: serialize(editor) });
        break;
      case NewsEditorType.TITLE:
        onSave({ ...news, title: serialize(editor) });
        break;
      case NewsEditorType.SUBTITLE:
        onSave({ ...news, headline: serialize(editor) });
        break;

      default:
        onSave({
          ...news,
          enrichedContent: serialize(editor),
          title: serialize(editor),
          headline: serialize(editor),
        });
        break;
    }
  };

  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full">
      <Slate
        editor={editor}
        value={initialValue}
        /*onChange={(value) => {
            const isAstChange = editor.operations.some(
              (op) => 'set_selection' !== op.type
            );
            if (isAstChange) {
              setContent(value);
            }
          }}*/
      >
        <div className="flex flex-col w-full">
          <div
            className="flex flex-row items-center space-x-8 pb-2 w-fit py-1 px-4 rounded-t-lg"
            style={{ backgroundColor: '#F5F7FE' }}
          >
            <div className="flex flex-row items-center space-x-0.5">
              <MarkButton format="bold" Icon={<FormatBold />} />
              <MarkButton format="italic" Icon={<FormatItalic />} />
              <MarkButton format="underline" Icon={<FormatUnderlined />} />
            </div>
            <div className="flex flex-row items-center space-x-0.5">
              {/*<MarkButton format="code" Icon={<FormatBold />} />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />*/}
              <BlockButton format="left" Icon={<FormatAlignLeft />} />
              <BlockButton format="center" Icon={<FormatAlignCenter />} />
              <BlockButton format="right" Icon={<FormatAlignRight />} />
              <BlockButton format="justify" Icon={<FormatAlignJustify />} />
            </div>
            <div className="flex flex-row items-center space-x-0.5">
              <BlockButton format="bulleted-list" Icon={<FormatBulletList />} />
              <BlockButton format="numbered-list" Icon={<FormatNumberList />} />
            </div>
            <div className="flex flex-row items-center space-x-0.5">
              <MarkButton format="highlight" Icon={<FormatColor />} />
            </div>
          </div>
          <div
            className="flex w-full py-1 px-4 rounded-tr-lg"
            style={{ backgroundColor: '#F5F7FE' }}
          >
            <Editable
              className="bg-white min-w-full max-w-fit mt-2 p-1"
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={t('Inserire il testo della news qui')}
              spellCheck
              autoFocus
              /*onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}*/
            />
          </div>
        </div>
      </Slate>
      <div
        className="flex items-center w-full justify-end py-1 px-4 rounded-b-lg"
        style={{ backgroundColor: '#F5F7FE' }}
      >
        <div className="flex cursor-pointer" onClick={onCancel}>
          <Text text="Annulla" />
        </div>
        <div className="flex cursor-pointer ml-4" onClick={onClickSave}>
          <Text text="Salva" style={{ color: '#5B84EF' }} />
        </div>
      </div>
    </div>
  );
};

export const toggleBlock = (
  editor: BaseEditor & ReactEditor,
  format: string
) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: any;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (
  editor: BaseEditor & ReactEditor,
  format: string
) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isBlockActive = (
  editor: BaseEditor & ReactEditor,
  format: any,
  blockType = 'type'
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        // @ts-ignore
        n[blockType] === format,
    })
  );

  return !!match;
};

export const isMarkActive = (
  editor: BaseEditor & ReactEditor,
  format: string | number
) => {
  const marks = Editor.marks(editor);
  // @ts-ignore
  return marks ? marks[format] === true : false;
};

export const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.highlight) {
    children = <span style={{ backgroundColor: '#f8fb63' }}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

export const BlockButton = ({ format, Icon }: any) => {
  const editor = useSlate();
  return (
    <EditorButton
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <SvgIcon svg={Icon} />
    </EditorButton>
  );
};

export const MarkButton = ({ format, Icon }: any) => {
  const editor = useSlate();
  return (
    <EditorButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <SvgIcon svg={Icon} />
    </EditorButton>
  );
};

export const EditorButton = React.forwardRef(
  (
    {
      active,
      reversed,
      ...props
    }: PropsWithChildren<{
      active: boolean;
      reversed: boolean;
      [key: string]: unknown;
    }>,
    ref: LegacyRef<HTMLSpanElement> | undefined
  ) => (
    <span
      {...props}
      ref={ref}
      style={{
        cursor: 'pointer',
        fill: reversed
          ? active
            ? 'white'
            : '#aaa'
          : active
          ? 'black'
          : '#ccc',
      }}
    />
  )
);

EditorButton.displayName = 'EditorButton';
