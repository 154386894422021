import React, { useState, FC, useRef, useEffect } from 'react';
import { ChatBot, UserIcon, SendIcon } from '../../svg';
import { Text } from '../Text';
import { CategoryDropdown } from './../CategoryDropdown';

interface ChatAiProps {
  onSendPressAI: (message: string) => Promise<string>;
  currentPressDraggable: {};
}

export const ChatAi: FC<ChatAiProps> = ({
  onSendPressAI,
  currentPressDraggable,
}) => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [messages, setMessages] = useState<
    { text: string; sender: 'user' | 'Ai' }[]
  >([]);
  const [inputMessage, setInputMessage] = useState<string>('');

  const handleChatOpen = () => {
    if (isChatOpen) {
      setIsChatOpen(false);
    } else {
      setIsChatOpen(true);
    }
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: inputMessage, sender: 'user' },
      ]);

      onSendPressAI(inputMessage)
        .then((aiResponse: string) => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: aiResponse, sender: 'Ai' },
          ]);
        })
        .catch((aiResponse: string) => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: aiResponse, sender: 'Ai' },
          ]);
        });
      setInputMessage('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Aggiungi questo useEffect per stampare currentPressDraggable
  useEffect(() => {
    console.log(
      'currentPressDraggable nel componente ChatAi:',
      currentPressDraggable
    );
    // iterate over currentPressDraggable to create a list of categories (keys)
    const categories = Object.keys(currentPressDraggable);

    console.log('categories:', categories);
  }, [currentPressDraggable]); // L'effect si ri-esegue solo se currentPressDraggable cambia

  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputMessage(value);

    if (value.includes('@')) {
      const parts = value.split('@');
      const search = parts[parts.length - 1].toLowerCase();
      const matches = Object.keys(currentPressDraggable).filter((categoria) =>
        categoria.toLowerCase().startsWith(search)
      );
      if (matches.length > 0) {
        setShowDropdown(true);
        setFilteredCategories(matches);
      } else {
        setShowDropdown(false);
      }
    } else {
      setShowDropdown(false);
    }
  };

  const selectCategory = (categoria: string) => {
    // Trova l'ultima posizione di '@' nel messaggio
    const lastIndex = inputMessage.lastIndexOf('@');

    // Se '@' è presente, inserisce la categoria selezionata dopo l'ultimo '@'
    // Altrimenti, aggiunge '@' seguito dalla categoria alla fine del messaggio
    const updatedMessage =
      lastIndex !== -1
        ? `${inputMessage.substring(0, lastIndex + 1)}"${categoria}"`
        : `${inputMessage}@"${categoria}"`;

    setInputMessage(updatedMessage);

    setShowDropdown(false);
  };

  return (
    <>
      <ChatBot
        onClick={handleChatOpen}
        className="fixed bottom-0 right-0 z-50 w-16 h-16 m-10"
      />

      {isChatOpen && (
        <div className="fixed bottom-28 right-28 z-50 w-[500px] h-96 bg-white border border-gray-300 rounded-t-3xl rounded-tr-3xl rounded-l-3xl">
          <div className="flex flex-col h-full">
            <Text text={'Chat'} className="text-center font-bold mt-2" />

            <div className="flex flex-col flex-grow px-4 py-2 overflow-y-auto">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex items-center mb-2 last:mb-0 ${
                    message.sender === 'user' ? 'justify-end' : ''
                  }`}
                >
                  {message.sender === 'Ai' && (
                    <ChatBot className="w-8 h-8 mr-2" />
                  )}
                  <div
                    className={`px-4 py-2 ${
                      message.sender === 'user'
                        ? 'mr-2 bg-blue-500 text-white'
                        : 'bg-gray-200 text-black'
                    } rounded-full whitespace-pre-wrap break-words`}
                  >
                    {message.text}
                  </div>
                  {message.sender === 'user' && (
                    <div className="flex items-center justify-center p-1 w-8 h-8 text-slate-50 bg-gray-400 border border-gray-300 rounded-full">
                      <UserIcon className="w-8 h-8" />
                    </div>
                  )}
                </div>
              ))}
              <div ref={messagesEndRef} />{' '}
              {/* Elemento per lo scroll automatico */}
            </div>

            <div className="flex items-center h-16 px-4 border-t border-gray-300 pt-2 pb-2">
              <div className="flex flex-grow items-center border border-gray-300 rounded-3xl h-12">
                <input
                  type="text"
                  value={inputMessage}
                  // onChange={(event) => setInputMessage(event.target.value)} - old changed in 19/03/2024
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  className="flex-grow pl-4 pr-4 rounded-l-3xl decoration-none focus:outline-none"
                  placeholder="Scrivi qui la tua richiesta"
                />
                {showDropdown && (
                  <div
                    className="bg-white border border-gray-300 rounded-md mr-10 mb-32"
                    style={{ bottom: '100%', left: 0 }}
                  >
                    <CategoryDropdown
                      categories={filteredCategories}
                      onSelect={selectCategory}
                    />
                  </div>
                )}
                <button
                  onClick={handleSendMessage}
                  className="flex-shrink-0 bg-blue-500 text-white rounded-full p-2 ml-2 mr-2"
                >
                  <SendIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
