import { ChartTrendTopic, NewsDetail, TrendTopic } from '@nwa/graphql';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDetailNews } from '../../redux/newsSelection/actions';
import { DownArrow } from '../../svg';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';
import { DivWithHtml } from '../DivWithHtml';
import { TrendChart } from './TrendChart';

export interface TrendElementProps {
  topicItem: TrendTopic;
  chart?: ChartTrendTopic;
  onVisualizzaTutte: (topic: string) => void;
}

export const TrendElement: FC<TrendElementProps> = ({
  topicItem,
  chart,
  onVisualizzaTutte,
}) => {
  const { topic, newsCount, newsList } = topicItem;
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(true);
  const setDetailNewsAction = useCallback(
    (news: NewsDetail) => {
      dispatch(setDetailNews(news));
    },
    [dispatch]
  );
  return (
    <div
      className="flex py-4 px-4 flex-col max-w-full rounded justify-between m-1 bg-white items-center"
      style={{
        border: '1px solid white',
      }}
    >
      <div className="flex items-center my-0 mx-1 w-full">
        <div className="flex flex-row items-center justify-between pl-2 pr-4 w-4/12">
          <Text
            text={topic}
            skipTranslation={true}
            style={{
              fontSize: '1.125rem',
              lineHeight: '1.75rem',
            }}
            className="font-bold"
          />
          <Text
            text={`${newsCount} news`}
            skipTranslation={true}
            style={{
              fontSize: '1rem',
              lineHeight: '1.5rem',
            }}
            className="text-[#ccc]"
          />
        </div>
        <div className="flex flex-col items-left justify-between px-4 w-6/12 overflow-ellipsis overflow-hidden whitespace-nowrap space-y-1.5">
          {collapsed &&
            newsList.map((newsI) => (
              <div className="flex flex-row space-x-1" key={newsI.summary.id}>
                <DivWithHtml
                  html={newsI.summary.title}
                  className="font-semibold"
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1rem',
                  }}
                />
                <DivWithHtml
                  html={newsI.content}
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1rem',
                  }}
                />
              </div>
            ))}
        </div>
        <div className="flex flex-col items-center w-2/12">
          {collapsed && (
            <>
              <TrendChart
                key={topic}
                chart={chart}
                height={66}
                width={200}
                big={false}
              />
              <Text
                text={'Ultimi 3 mesi'}
                style={{
                  fontSize: '0.75rem',
                  lineHeight: '1rem',
                }}
                className="text-[#ccc]"
              />
            </>
          )}
        </div>
        <div className="flex flex-row w-5">
          <SvgIcon
            svg={
              <DownArrow
                style={{
                  fill: 'black',
                  transform: !collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            }
            pointer={true}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
      </div>
      {!collapsed && (
        <div className="flex flex-col items-center my-0 mx-1 w-full">
          <div className="flex flex-row items-start px-2 pt-8 w-full">
            <Text text="NOTIZIE NEL TEMPO" className="text-[#ccc]" />
          </div>
          <div className="flex px-2 w-full border-b">
            <TrendChart
              key={topic + 'big'}
              chart={chart}
              height={300}
              width={1400}
              big={true}
            />
          </div>
          {newsList.length > 0 && (
            <>
              <div className="flex flex-row items-start px-2 pt-8 w-full">
                <Text text="NOTIZIE CORRELATE" className="text-[#ccc]" />
              </div>
              <div className="flex flex-row items-center justify-between px-4 pt-4 w-full space-x-4">
                {newsList.map((newsI) => (
                  <div
                    key={newsI.summary.id + '!collapsed'}
                    className="flex flex-col space-y-1 bg-[#EFEFEF] rounded p-2 cursor-pointer"
                    style={{ width: `${88 / newsList.length}%` }}
                    onClick={() => setDetailNewsAction(newsI)}
                  >
                    <DivWithHtml
                      html={newsI.summary.title}
                      className="font-semibold"
                      style={{
                        fontSize: '0.875rem',
                        lineHeight: '1rem',
                      }}
                    />
                    {newsI.summary.headline && (
                      <DivWithHtml
                        html={newsI.summary.headline}
                        className="italic"
                        style={{
                          fontSize: '0.875rem',
                          lineHeight: '1rem',
                        }}
                      />
                    )}
                    <DivWithHtml
                      html={newsI.content}
                      style={{
                        fontSize: '0.875rem',
                        lineHeight: '1rem',
                      }}
                      className="overflow-ellipsis overflow-hidden whitespace-nowrap"
                    />
                  </div>
                ))}
                <div className="flex flex-col w-[10%] h-full">
                  <div
                    className="flex flex-row items-center justify-between cursor-pointer mt-auto"
                    onClick={() => onVisualizzaTutte(topic)}
                  >
                    <Text
                      text="Visualizza tutte"
                      className="text-[#5B84EF] w-10/12"
                      style={{
                        fontSize: '0.75rem',
                        lineHeight: '1rem',
                      }}
                    />
                    <SvgIcon
                      svg={
                        <DownArrow style={{ transform: 'rotate(270deg)' }} />
                      }
                      className="w-2/12"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
