import { FC, useState } from 'react';
import { GenericSelect } from '../GenericSelect';
import { getDateAsString } from '../../utils/dateUtils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { Calendar } from '../../svg';

export interface TrendDateFilterProps {
  dateFilter: string | undefined;
  setDateFilter: (dateFilter: string | undefined) => void;
}

export const TrendDateFilter: FC<TrendDateFilterProps> = ({
  dateFilter,
  setDateFilter,
}) => {
  //diretta oggetto nullo
  //ieri: ieri a mezzanotte to oggi a mezzanotte
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let tonight = new Date();
  tonight.setHours(23, 59, 0, 0);
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let dayBeforeYesterday = new Date(yesterday);
  dayBeforeYesterday.setDate(yesterday.getDate() - 1);
  let fullYear = new Date();
  fullYear.setFullYear(today.getFullYear() - 1);

  const [currentValue, setCurrentValue] = useState<any>(
    dateFilter
      ? {
          value: dateFilter,
          label: `${getDateAsString(
            new Date(Number.parseInt(dateFilter)).getTime()
          )}`,
        }
      : null
  );
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [datePickerDate, setDatePickerDate] = useState<Date | null>(null);
  const onChangeDatepicker = (date: Date) => {
    setDatePickerDate(date);
    setDateFilter(date.getTime().toString());
    let value: any = {
      value: date.getTime().toString(),
      label: `${getDateAsString(date.getTime())}`,
    };
    setCurrentValue(value);
    setShowDatepicker(false);
  };

  const options = [
    {
      value: yesterday.getTime().toString(),
      label: 'Ieri',
    },
    {
      value: dayBeforeYesterday.getTime().toString(),
      label: "L'altroieri",
    },
  ];

  const onOggiClick = () => {
    setCurrentValue(null);
    setDateFilter(undefined);
    setShowDatepicker(false);
  };

  const onChange = (data: any) => {
    setCurrentValue(data);
    setDateFilter(data.value);
  };
  return (
    <>
      <GenericSelect
        id="TrendDate"
        placeholder={'Scegli periodo'}
        onChange={(data: any) => onChange(data)}
        options={options}
        showSelectedOption={true}
        showSelectionCircle={false}
        showSelectedOptionLabel={true}
        showButtonControl={true}
        disableBorder={true}
        buttonControlLabel="Oggi"
        onButtonClick={() => onOggiClick()}
        actions={[
          {
            label: 'Scegli date',
            Icon: <Calendar />,
            value: '1',
            onClick: () => setShowDatepicker(true),
          },
        ]}
        value={currentValue}
        label={''}
        isDisabled={false} // modificato
      />
      {showDatepicker && (
        <Modal
          title={'Scegli date'}
          showCloseButton={true}
          onClickCloseButton={() => setShowDatepicker(false)}
        >
          <DatePicker
            selected={datePickerDate}
            onChange={onChangeDatepicker}
            minDate={fullYear}
            maxDate={today}
            inline
          />
        </Modal>
      )}
    </>
  );
};
