import { BookmarkColor, Category, Keyword, Tag } from '@nwa/graphql';
import { createReducer } from '@reduxjs/toolkit';
import {
  BookmarkObject,
  bookmarksList,
  ContentTypeObject,
  contentTypes,
  ProviderObject,
  providers,
  SentimentObject,
  sentiments,
} from './costant';
import {
  removeKeyword,
  addNewCategory,
  removeCategory,
  addNewKeyword,
  setCategories,
  setCurrentBookmarkColor,
  setKeywords,
  setTags,
} from './actions';

export interface FilterChoicesReducerState {
  contentTypes: ContentTypeObject[];
  currentBookmarkColor: string;
  bookmarksList: BookmarkObject[];
  keywords: Keyword[];
  keywordsLoaded: boolean;
  providers: ProviderObject[];
  categories: Category[];
  tags: Tag[];
  sentiments: SentimentObject[];
}

const defaultState: FilterChoicesReducerState = {
  contentTypes: contentTypes,
  currentBookmarkColor: BookmarkColor.ORANGE,
  bookmarksList: bookmarksList,
  keywords: [],
  keywordsLoaded: false,
  providers: providers,
  categories: [],
  tags: [],
  sentiments: sentiments,
};

export const filterChoicesReducer = createReducer<FilterChoicesReducerState>(
  defaultState,
  {
    [setCurrentBookmarkColor.type]: (
      state,
      action
    ): FilterChoicesReducerState => ({
      ...state,
      currentBookmarkColor: action.payload,
    }),
    [setKeywords.type]: (state, action): FilterChoicesReducerState => ({
      ...state,
      keywords: action.payload,
      keywordsLoaded: true,
    }),
    [setCategories.type]: (state, action): FilterChoicesReducerState => ({
      ...state,
      categories: action.payload,
    }),
    [setTags.type]: (state, action): FilterChoicesReducerState => ({
      ...state,
      tags: action.payload,
    }),
    [addNewKeyword.type]: (state, action): FilterChoicesReducerState => ({
      ...state,
      keywords: [...state.keywords, action.payload],
    }),
    [removeKeyword.type]: (state, action): FilterChoicesReducerState => ({
      ...state,
      keywords: state.keywords.filter(
        (keyword) => keyword.id !== action.payload
      ),
    }),
    [addNewCategory.type]: (state, action): FilterChoicesReducerState => ({
      ...state,
      categories: [...state.categories, action.payload],
    }),
    [removeCategory.type]: (state, action): FilterChoicesReducerState => ({
      ...state,
      categories: state.categories.filter(
        (category) => category.id !== action.payload
      ),
    }),
  }
);
