import { TrendTopic, SerpAPIGeo, ChartForTopic } from '@nwa/graphql';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  GET_CHART_FOR_TOPICS,
  GET_TREND_TOPICS,
} from '../../graphql/news/queries';
import { setDetailNews } from '../../redux/newsSelection/actions';
import { addLoading, removeLoading } from '../../redux/utils/actions';
import { Divider } from '../../svg';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { NavigationBackComponent } from '../NavigationBack';
import { NewsDetailWrapper } from '../NewsDetailWrapper';
import { TrendElement, TrendElementProps } from './TrendElement';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';
import { useLazyQueryHook } from '../hooks/useLazyQueryHook';
import { apolloClient } from '../../graphql';
import { TrendGeoFilter } from './TrendGeoFilter';
import { TrendDateFilter } from './TrendDateFilter';
import { setNewsFilter } from '../../redux/newsFilter/actions';
import {
  removeDraftWorkingArea,
  resetWorkingAreaFilter,
  setWorkingAreaFilter,
} from '../../redux/workingAreas/actions';

export const Trends: FC = () => {
  const dispatch = useDispatch();
  const [topicList, setTopicList] = useState<TrendTopic[]>([]);
  const [chartList, setChartList] = useState<ChartForTopic[]>([]);
  useEffect(() => {
    dispatch(setDetailNews(undefined));
  }, [dispatch]);

  const [getTrendTopics] = useLazyQueryHook({ queryGql: GET_TREND_TOPICS });
  const [geo, setGeo] = useState<SerpAPIGeo>(SerpAPIGeo.IT);
  const [dateFilter, setDateFilter] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const onVisualizzaTutte = useCallback(
    (topic: string) => {
      let time: any = undefined;
      if (dateFilter) {
        const start = new Date(Number.parseInt(dateFilter));
        start.setHours(0, 0, 0, 0);
        const end = new Date(Number.parseInt(dateFilter));
        end.setHours(23, 59, 59, 999);
        time = { from: start.getTime(), to: end.getTime() };
      }
      navigate('/newswire');
      dispatch(removeDraftWorkingArea());
      dispatch(
        setNewsFilter({
          keywords: [],
          providers: [],
          contentTypes: [],
          dateRange: time,
          expressions: [],
          fullSearchText: topic.replaceAll(' ', ' AND '),
          fullSearchTextError: false,
        })
      );
      dispatch(
        setWorkingAreaFilter({
          contentTypes: [],
          fullSearchText: topic.replaceAll(' ', ' AND '),
          keywords: [],
          providers: [],
          fullSearchTextError: false,
          dateRange: time,
        })
      );
      dispatch(resetWorkingAreaFilter());
    },
    [dateFilter, dispatch, navigate]
  );

  const childProps: TrendElementProps[] = useMemo(() => {
    let ret: TrendElementProps[] = [];
    topicList.forEach((topicItem) => {
      ret.push({
        topicItem: topicItem,
        chart: chartList.find((c) => c.topic === topicItem.topic)?.chart,
        onVisualizzaTutte: onVisualizzaTutte,
      });
    });
    return ret;
  }, [chartList, onVisualizzaTutte, topicList]);

  const getTrendTopicsAction = useCallback(() => {
    dispatch(addLoading());
    getTrendTopics({
      variables: {
        input: {
          geo,
          dateFilter: dateFilter ? Number.parseInt(dateFilter) : undefined,
        },
      },
    })
      .then((res: any) => {
        // get topics
        const rawTopics =
          res.data[firstLowerCaseGqlDefinition(GET_TREND_TOPICS)];

        // clean topics from semicolon and too long topics
        const cleanedTopics = rawTopics.map((topicObj: TrendTopic) => {
          // Rimozione virgola, poi dividiamo il topic in parole
          let words = topicObj.topic.split(',')[0].split(' ');

          // Applichiamo la logica per assicurarci che il topic sia composto da massimo due parole,
          // includendo la terza parola se la seconda è troppo corta

          if (words.length > 1 && words[1].length < 3 && words.length > 2) {
            // Aggiungi la terza parola se esiste, altrimenti usa solo le prime due
            topicObj.topic = words.slice(0, 3).join(' ');
          } else {
            // Altrimenti, limita il topic alle prime due parole
            topicObj.topic = words.slice(0, 2).join(' ');
          }

          return {
            ...topicObj,
            topic: topicObj.topic,
          };
        });

        console.log('rawTopics', rawTopics);
        console.log('cleanedTopics', cleanedTopics);

        setTopicList(cleanedTopics);

        // setTopicList(res.data[firstLowerCaseGqlDefinition(GET_TREND_TOPICS)]); old
        if (cleanedTopics.length > 0) {
          // create topics string for query
          let firstTopicsString = cleanedTopics
            .map((topic: TrendTopic) => topic.topic)
            .slice(0, 5)
            .join(',');
          /* Check if the string contains more than 100 chars if there 
           are more than 100 chars remove the last topic*/
          if (firstTopicsString.length > 100) {
            console.log('firstTopicsString length: ', firstTopicsString.length);
            let topicsArray = firstTopicsString.split(',');
            topicsArray.pop(); // Rimuove l'ultimo elemento
            firstTopicsString = topicsArray.join(','); // Unisce gli elementi dell'array in una stringa, separati da una virgola
          }
          console.log('firstTopicsString', firstTopicsString);
          apolloClient()
            .query({
              query: GET_CHART_FOR_TOPICS,
              variables: {
                input: {
                  geo,
                  topics: firstTopicsString,
                },
              },
            })
            .then((res2: any) => {
              setChartList((prev) => [
                ...prev,
                ...res2.data[firstLowerCaseGqlDefinition(GET_CHART_FOR_TOPICS)],
              ]);
            });
          if (cleanedTopics.length > 5) {
            // create second topics string for query
            let secondTopicsString = cleanedTopics
              .map((topic: TrendTopic) => topic.topic)
              .slice(5)
              .join(',');
            // check if the string contains more than 100 chars
            if (secondTopicsString.length > 100) {
              console.log(
                'secondTopicsString length: ',
                secondTopicsString.length
              );
              let topicsArray = secondTopicsString.split(',');
              topicsArray.pop(); // Rimuove l'ultimo elemento
              secondTopicsString = topicsArray.join(','); // Unisce gli elementi dell'array in una stringa, separati da una virgola
            }
            console.log('secondTopicsString', secondTopicsString);
            apolloClient()
              .query({
                query: GET_CHART_FOR_TOPICS,
                variables: {
                  input: {
                    geo,
                    topics: secondTopicsString,
                  },
                },
              })
              .then((res2: any) => {
                setChartList((prev) => [
                  ...prev,
                  ...res2.data[
                    firstLowerCaseGqlDefinition(GET_CHART_FOR_TOPICS)
                  ],
                ]);
              });
          }
        }
      })
      .finally(() => dispatch(removeLoading()));
  }, [dateFilter, dispatch, geo, getTrendTopics]);

  useEffect(() => {
    getTrendTopicsAction();
  }, [getTrendTopicsAction]);

  return (
    <>
      <div style={{ backgroundColor: '#FAFAFA' }} className="h-full">
        <div
          style={{ backgroundColor: '#FAFAFA' }}
          className="flex flex-col h-screen w-full items-center justify-around"
        >
          <div
            className="h-12 w-full pl-3 py-2 mt-1 flex items-center justify-between bg-white"
            style={{ boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="flex items-center">
              <NavigationBackComponent />
              <SvgIcon svg={<Divider className="m-1" />} />
              <Text
                text="Argomenti in tendenza"
                className="ml-3 font-semibold"
              />
            </div>
            <div className="flex items-center justify-end space-x-3 mr-5">
              <TrendDateFilter
                setDateFilter={setDateFilter}
                dateFilter={dateFilter}
              />
              <TrendGeoFilter setGeo={setGeo} geo={geo} />
            </div>
          </div>
          <div className="flex h-full w-full py-10 px-20 rounded-xl flex-col scrollbar overflow-y-auto mt-0.5 mb-8">
            <div className="flex h-fit w-full rounded-xl flex-col pb-4">
              {childProps.map((item) => (
                <TrendElement
                  key={item.topicItem.topic}
                  topicItem={item.topicItem}
                  chart={item.chart}
                  onVisualizzaTutte={onVisualizzaTutte}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <NewsDetailWrapper />
    </>
  );
};
